// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.common with an alias.
import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
    faCircleQuestion,
    faClock,
    faPenToSquare,
    faTrashCan,
    faShareSquare,
    faShareFromSquare,
    faEnvelope,
} from '@fortawesome/free-regular-svg-icons';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';

import { BTooltip, BCard, BCardBody, BCardText, BCardGroup, BCardHeader, BCollapse } from 'bootstrap-vue';
import { CollapsePlugin, VBTogglePlugin } from 'bootstrap-vue';

import { setupAxiosInterceptors } from '@/shared/config/axios-interceptor';
import { createPinia, PiniaVuePlugin } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

import App from './app.vue';
import Vue2Filters from 'vue2-filters';
import { ToastPlugin } from 'bootstrap-vue';
import router from './router';
import * as config from './shared/config/config';
import * as bootstrapVueConfig from './shared/config/config-bootstrap-vue';
import JhiItemCountComponent from './shared/jhi-item-count.vue';
import JhiSortIndicatorComponent from './shared/sort/jhi-sort-indicator.vue';
import InfiniteLoading from 'vue-infinite-loading';
import HealthService from './admin/health/health.service';
import MetricsService from './admin/metrics/metrics.service';
import LogsService from './admin/logs/logs.service';
import ConfigurationService from '@/admin/configuration/configuration.service';
import ActivateService from './account/activate/activate.service';
import RegisterService from './account/register/register.service';
import UserManagementService from './admin/user-management/user-management.service';
import LoginService from './account/login.service';
import AccountService from './account/account.service';
import AlertService from './shared/alert/alert.service';

import '../content/scss/global.scss';
import '../content/scss/konto.scss';
import '../content/scss/vendor.scss';
import TranslationService from '@/locale/translation.service';
/* tslint:disable */

import KontoService from '@/pages/konto/konto.service';
import TeamService from '@/pages/team/team.service';
import AccountComponent from './konto/account/account.vue';
import OrderingCardComponent from './ordering/orderring-card/ordering-card.vue';
import AddblockDetector from './core/adblockdetector/adblock-detector.vue';
import imprintService from '@/pages/imprint/imprint.service';
import privacyService from '@/pages/privacy/privacy.service';
import contactService from '@/pages/contact/contact.service';
import cookiesService from '@/pages/cookies/cookies.service';
import bookingoverviewService from '@/pages/bookingoverview/bookingoverview.service';
import BookingService from './konto/services/booking.service';
import AnonymousUserLoginService from './konto/services/user-login.service';
import correctionService from '@/pages/correction/correction.service';
import OrderingService from './ordering/services/ordering.service';
import VueCurrencyInput from 'vue-currency-input';
import VueMeta from 'vue-meta';
// jhipster-needle-add-entity-service-to-main-import - JHipster will import entities services here

/* tslint:enable */
Vue.config.productionTip = false;
config.initVueApp(Vue);
config.initFortAwesome(Vue); // TODO remove default icon set
library.add(faCircleQuestion, faClock, faPenToSquare, faTrashCan, faShareSquare, faShareFromSquare, faEnvelope);

bootstrapVueConfig.initBootstrapVue(Vue);
Vue.use(Vue2Filters);
Vue.use(ToastPlugin);
Vue.use(VueMeta);
Vue.component('b-tooltip', BTooltip);
Vue.component('b-card', BCard);
Vue.component('b-card-text', BCardText);
Vue.component('b-card-body', BCardBody);
Vue.component('b-card-group', BCardGroup);
Vue.component('b-card-header', BCardHeader);
Vue.component('b-collapse', BCollapse);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('jhi-item-count', JhiItemCountComponent);
Vue.component('jhi-sort-indicator', JhiSortIndicatorComponent);
Vue.component('infinite-loading', InfiniteLoading);
Vue.component('account', AccountComponent);
Vue.component('adblockerDetector', AddblockDetector);
Vue.component('ordering-card', OrderingCardComponent);

const pluginOptions = {
    /* see config reference */
    globalOptions: {
        currency: 'EUR',
        valueRange: { min: 0, max: 10000000 },
        locale: 'de',
        'allow-negative': false,
    },
};
Vue.use(VueCurrencyInput, pluginOptions);
Vue.use(CollapsePlugin);
Vue.use(VBTogglePlugin);

Vue.filter('formatDate', function (value) {
    if (value) {
        return new Date(value).toLocaleDateString('de', { dateStyle: 'short' });
    }
    return '';
});

Vue.filter('formatTime', function (value) {
    if (value) {
        return new Date(value).toLocaleTimeString('de', { timeStyle: 'short' });
    }
    return '';
});

Vue.filter('toCurrency', function (value) {
    if (typeof value !== 'number') {
        return value;
    }
    const formatter = new Intl.NumberFormat('de', {
        style: 'currency',
        currency: 'EUR',
    });
    return formatter.format(value);
});

Vue.use(PiniaVuePlugin);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const i18n = config.initI18N(Vue);
const store = config.initVueXStore(Vue);

const translationService = new TranslationService(store, i18n);
const loginService = new LoginService();
const accountService = new AccountService(store, translationService, router);

router.beforeEach(async (to, from, next) => {
    if (!to.matched.length) {
        next('/not-found');
    } else if (to.meta && to.meta.authorities && to.meta.authorities.length > 0) {
        accountService.hasAnyAuthorityAndCheckAuth(to.meta.authorities).then(value => {
            if (!value) {
                sessionStorage.setItem('requested-url', to.fullPath);
                next('/forbidden');
            } else {
                next();
            }
        });
    } else {
        // no authorities, so just proceed
        next();
    }
});

/* tslint:disable */
const vue = new Vue({
    el: '#app',
    components: { App },
    template: '<App/>',
    router,
    provide: {
        loginService: () => loginService,
        activateService: () => new ActivateService(),
        registerService: () => new RegisterService(),
        userManagementService: () => new UserManagementService(),
        healthService: () => new HealthService(),
        configurationService: () => new ConfigurationService(),
        logsService: () => new LogsService(),
        metricsService: () => new MetricsService(),
        bookingService: () => new BookingService(),
        orderingService: () => new OrderingService(),
        translationService: () => translationService,
        userLoginService: () => new AnonymousUserLoginService(),
        kontoService: () => new KontoService(),
        teamService: () => new TeamService(),
        imprintService: () => new imprintService(),
        privacyService: () => new privacyService(),
        contactService: () => new contactService(),
        cookiesService: () => new cookiesService(),
        bookingoverviewService: () => new bookingoverviewService(),
        correctionService: () => new correctionService(),
        // jhipster-needle-add-entity-service-to-main - JHipster will import entities services here
        accountService: () => accountService,
        alertService: () => new AlertService(),
    },
    i18n,
    store, // TODO replace Vuex Store with pinia
    pinia,
});

setupAxiosInterceptors(
    error => {
        const url = error.response?.config?.url;
        const status = error.status || error.response.status;
        if (status === 401) {
            // Store logged out state.
            store.commit('logout');
            if (!url.endsWith('api/account') && !url.endsWith('api/authenticate')) {
                // Ask for a new authentication
                loginService.openLogin(vue);
                return;
            }
        }
        console.log('Unauthorized!');
        return Promise.reject(error);
    },
    error => {
        console.log('Server error!');
        return Promise.reject(error);
    }
);
