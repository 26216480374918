var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "shadow-sm": true,
        "m-4": true,
        "p-2": true,
        border: true,
        "border-primary": true,
        "bg-light": true,
      },
    },
    [
      _c("div", { staticClass: "m-3" }, [
        _c("b", { staticClass: "m-2" }, [_vm._v("Restaurant Favoriten")]),
        _vm._v(" "),
        _c("div", { staticClass: "accountOverview m-2" }, [
          _c(
            "ul",
            _vm._l(_vm.orderingFavoritesStore.favorites, function (favorite) {
              return _c("li", { key: favorite.id, staticClass: "m-2" }, [
                _vm.editFavoriteId === favorite.id
                  ? _c("span", [
                      _c(
                        "div",
                        { staticClass: "row" },
                        [
                          _c("b-form-input", {
                            staticClass: "col-2 m-2",
                            attrs: {
                              type: "text",
                              placeholder: "Restaurant-Name",
                              required: "",
                            },
                            on: {
                              keyup: function ($event) {
                                return _vm.checkModifiedUrl()
                              },
                            },
                            model: {
                              value: _vm.modInputFavoriteNameFieldValue,
                              callback: function ($$v) {
                                _vm.modInputFavoriteNameFieldValue = $$v
                              },
                              expression: "modInputFavoriteNameFieldValue",
                            },
                          }),
                          _vm._v(" "),
                          _c("b-form-input", {
                            staticClass: "col-2 m-2",
                            attrs: {
                              type: "url",
                              placeholder: "URL Menü-Karte http://beispiel.de",
                              pattern: "https?://.*",
                              required: "",
                            },
                            on: {
                              keyup: function ($event) {
                                return _vm.checkModifiedUrl()
                              },
                            },
                            model: {
                              value: _vm.modInputFavoriteUrlFieldValue,
                              callback: function ($$v) {
                                _vm.modInputFavoriteUrlFieldValue = $$v
                              },
                              expression: "modInputFavoriteUrlFieldValue",
                            },
                          }),
                          _vm._v(" "),
                          _c("b-form-input", {
                            staticClass: "col-2 m-2",
                            attrs: {
                              type: "tel",
                              placeholder: "Telefonnummer",
                            },
                            on: {
                              keyup: function ($event) {
                                return _vm.checkModifiedUrl()
                              },
                            },
                            model: {
                              value: _vm.modInputFavoritePhoneFieldValue,
                              callback: function ($$v) {
                                _vm.modInputFavoritePhoneFieldValue = $$v
                              },
                              expression: "modInputFavoritePhoneFieldValue",
                            },
                          }),
                          _vm._v(" "),
                          _c("b-form-input", {
                            staticClass: "col-2 m-2",
                            attrs: { type: "text", placeholder: "Notizen" },
                            on: {
                              keyup: function ($event) {
                                return _vm.checkModifiedUrl()
                              },
                            },
                            model: {
                              value: _vm.modInputFavoriteNotesFieldValue,
                              callback: function ($$v) {
                                _vm.modInputFavoriteNotesFieldValue = $$v
                              },
                              expression: "modInputFavoriteNotesFieldValue",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "b-button",
                            {
                              staticClass: "col-1 m-2",
                              attrs: {
                                type: "button",
                                variant: "primary",
                                disabled: !_vm.modUrlValid || !_vm.modified,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.saveFavorite()
                                },
                              },
                            },
                            [_vm._v("Speichern")]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-button",
                            {
                              staticClass: "col-1 m-2",
                              attrs: { type: "button", variant: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.cancelEdit()
                                },
                              },
                            },
                            [_vm._v(" Abbrechen")]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-button",
                            {
                              staticClass: "col-1 m-2",
                              attrs: { type: "button", variant: "danger" },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteFavorite()
                                },
                              },
                            },
                            [_vm._v("Löschen")]
                          ),
                        ],
                        1
                      ),
                    ])
                  : _c("span", [
                      _c(
                        "a",
                        { attrs: { href: favorite.url, target: favorite.id } },
                        [_vm._v(_vm._s(favorite.title))]
                      ),
                      _vm._v(" "),
                      favorite.phone
                        ? _c("span", [
                            _vm._v("    Tel:\n                            "),
                            _c(
                              "a",
                              {
                                staticStyle: { "font-weight": "normal" },
                                attrs: { href: "tel:" + favorite.phone },
                              },
                              [_vm._v(_vm._s(favorite.phone))]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      favorite.notes
                        ? _c("span", [
                            _vm._v(
                              "    Notizen: " + _vm._s(favorite.notes) + " "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(
                        "\n                            \n                        "
                      ),
                      _vm.editFavoriteId === null
                        ? _c(
                            "small",
                            { attrs: { title: "Bearbeiten" } },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "fa-regular fa-edit" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editFavorite(favorite.id)
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.editFavoriteId === favorite.id
                        ? _c(
                            "small",
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "fa-regular fa-trash-can" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteFavorite(favorite.id)
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
              ])
            }),
            0
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row" },
          [
            _c("b-form-input", {
              staticClass: "col-2 m-2",
              attrs: {
                type: "text",
                placeholder: "Restaurant-Name",
                required: "",
                title: "Bitte fülle dieses Feld aus",
              },
              on: {
                keyup: function ($event) {
                  return _vm.checkUrl()
                },
              },
              model: {
                value: _vm.inputFavoriteNameFieldValue,
                callback: function ($$v) {
                  _vm.inputFavoriteNameFieldValue = $$v
                },
                expression: "inputFavoriteNameFieldValue",
              },
            }),
            _vm._v(" "),
            _c("b-form-input", {
              staticClass: "col-2 m-2",
              attrs: {
                type: "url",
                placeholder: "Restaurant Menu Url http://beispiel.de",
                pattern: "https?://.*",
                required: "",
                title: "Bitte fülle dieses Feld aus",
              },
              on: {
                keyup: function ($event) {
                  return _vm.checkUrl()
                },
              },
              model: {
                value: _vm.inputFavoriteUrlFieldValue,
                callback: function ($$v) {
                  _vm.inputFavoriteUrlFieldValue = $$v
                },
                expression: "inputFavoriteUrlFieldValue",
              },
            }),
            _vm._v(" "),
            _c("b-form-input", {
              staticClass: "col-2 m-2",
              attrs: { type: "tel", placeholder: "Telefonnummer" },
              model: {
                value: _vm.inputFavoritePhoneFieldValue,
                callback: function ($$v) {
                  _vm.inputFavoritePhoneFieldValue = $$v
                },
                expression: "inputFavoritePhoneFieldValue",
              },
            }),
            _vm._v(" "),
            _c("b-form-input", {
              staticClass: "col-3 m-2",
              attrs: { type: "text", placeholder: "Notizen" },
              model: {
                value: _vm.inputFavoriteNotesFieldValue,
                callback: function ($$v) {
                  _vm.inputFavoriteNotesFieldValue = $$v
                },
                expression: "inputFavoriteNotesFieldValue",
              },
            }),
            _vm._v(" "),
            _c(
              "b-button",
              {
                staticClass: "col m-2",
                attrs: {
                  type: "button",
                  variant: "primary",
                  disabled: !_vm.urlValid,
                },
                on: {
                  click: function ($event) {
                    return _vm.doAddFavorite()
                  },
                },
              },
              [_vm._v("Hinzufügen")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }