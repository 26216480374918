import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import { useAccountStore } from '../stores/account-store';
import { useAdblockDetectorStore } from '@/core/adblockdetector/abocker-detector.store';
import AccountOverviewEntryComponent from './account-overview-entry/account-overview-entry.vue';
@Component({
    components: {
        'account-overview-entry': AccountOverviewEntryComponent,
    },
})
export default class AccountOverviewCardComponent extends Vue {
    @Prop()
    bookingOverviewMode: boolean;

    accountStore = useAccountStore();
    adblockerStatusStore = useAdblockDetectorStore();
    isCardHighlightActive = false;
    public doAddAccount(): void {
        this.accountStore.addEmptyAccount();
    }

    enableHighlightCard() {
        this.isCardHighlightActive = true;
    }

    disableHighlightCard() {
        this.isCardHighlightActive = false;
    }
}
