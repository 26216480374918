/* eslint-disable prettier/prettier */
import { IBookingEntry } from '@/shared/model/booking-entry.model';
import { IBookingSet } from '@/shared/model/booking-set.model';
import { IModificationSet } from '@/shared/model/modification-set.model';
import { defineStore } from 'pinia';
declare interface BookingsStoreModel {
    bookings: IBookingSet[];
    modifications: IModificationSet[];
}

export const useBookingsStore = defineStore('BookingsStore', {
    state: (): BookingsStoreModel => {
        return {
            bookings: [],
            modifications: [],
        };
    },
    actions: {
        clear() {
            this.bookings = [];
            this.modifications = [];
        },
        initWithBookData(bookings, modifications) {
            this.bookings = bookings;
            this.modifications = modifications;
        },
        addEntry(bookingSet: IBookingSet) {
            this.bookings.unshift(bookingSet);
        },
        addModification(modSet: IModificationSet) {
            const bookingSet: IBookingSet = this.bookings.find((booking: IBookingSet) => booking.id === modSet.bookingSetId);

            modSet.entries.forEach(moddedEntry => {
                moddedEntry.bookingSet = bookingSet;
                if (moddedEntry.predecessorId) {
                    const index = bookingSet.entries.findIndex(entry => entry.id === moddedEntry.predecessorId);
                    const originalEntry = bookingSet.entries[index];
                    // originalEntry.modificationSetId = modSet.id;
                    originalEntry.successorId = moddedEntry.id;
                    originalEntry.successor = moddedEntry;
                    moddedEntry.predecessor = originalEntry;
                    moddedEntry.predecessorId = originalEntry.id;
                    // bookingSet.entries[index] = moddedEntry;
                } else {
                    bookingSet.entries = [moddedEntry, ...bookingSet.entries];
                }
            });

            this.modifications.push(modSet);
        },
        addModificationOld(
            userId: number,
            bookingSetId: number,
            simpleModifications: SimpleBookingValueModel[],
            newBookingEntries: IBookingEntry[]
        ) {
            const bookingSet: IBookingSet = this.bookings.find(booking => booking.id === bookingSetId);

            const modSet: IModificationSet = {
                bookingSetId: bookingSetId,
                id: Math.floor(Math.random() * 100000000000) + 1,
                creationDateTime: bookingSet.creationDateTime,
                creatorUserId: userId,
            };

            simpleModifications.forEach(simpleModification => {
                const orignalEntry: IBookingEntry = bookingSet.entries.find(entry => entry.id === simpleModification.id);

                if (orignalEntry.minusValue !== simpleModification.minus || orignalEntry.plusValue !== simpleModification.plus) {
                    const successorEntry: IBookingEntry = {
                        id: Math.floor(Math.random() * 100000000000) + 1,
                        bookingSet: orignalEntry.bookingSet,
                        bookingAccountId: orignalEntry.bookingAccountId,
                        plusValue: simpleModification.plus,
                        minusValue: simpleModification.minus,
                        predecessor: orignalEntry,
                        modificationSetId: modSet.id,
                    };

                    orignalEntry.successorId = successorEntry.id;
                    orignalEntry.successor = successorEntry;

                    const index = bookingSet.entries.findIndex(entry => entry.id === orignalEntry.id);
                    bookingSet.entries[index] = successorEntry;
                }
            });

            newBookingEntries.forEach(newEntry => {
                newEntry.bookingSet = bookingSet;
                bookingSet.entries.push(newEntry);
            });

            bookingSet.creationDateTime = new Date();
            this.modifications.push(modSet);
        },
    },
    persist: true,
});
