import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import { useBookingsStore } from '../../stores/booking-store';

@Component
export default class AccountOverviewEntryComponent extends Vue {
    @Prop()
    account: { id: number; title: string };

    saldo = 0;
    latestBooking = null;

    created() {
        this.calculateSaldo();
        useBookingsStore().$subscribe((mutation, state) => {
            this.calculateSaldo();
        });
    }

    calculateSaldo() {
        const allBookings = useBookingsStore().bookings;
        const accountBookings = allBookings
            .map(booking => booking.entries.filter(entry => entry.bookingAccountId === this.account.id))
            .reduce((previous, current) => [...previous, ...current], []);

        const unmodifiedBookings = accountBookings.filter(entry => entry.successorId == null);

        const values = unmodifiedBookings.map(entry => (entry.minusValue != null ? -entry.minusValue : entry.plusValue));
        this.saldo = values.reduce((previous, current) => previous + current, 0);

        let latestAccountBookingDate = useBookingsStore()
            .bookings.filter(booking => booking.entries.find(entry => entry.bookingAccountId === this.account.id) != null)
            .reduce((latest, booking) => (latest == null || latest < booking.creationDateTime ? booking.creationDateTime : latest), null);

        latestAccountBookingDate = useBookingsStore()
            .modifications.filter(mod => mod.entries.find(entry => entry.bookingAccountId === this.account.id) != null)
            .reduce(
                (latest, mod) => (latest == null || latest < mod.creationDateTime ? mod.creationDateTime : latest),
                latestAccountBookingDate
            );

        this.latestBooking = latestAccountBookingDate;
    }
}
