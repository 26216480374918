import axios from 'axios';
import Component from 'vue-class-component';
import { Vue, Inject } from 'vue-property-decorator';
import AccountService from '@/account/account.service';
@Component({
    watch: {
        $route() {
            this.$root.$emit('bv::hide::modal', 'login-page');
        },
    },
})
export default class LoginForm extends Vue {
    @Inject('accountService')
    private accountService: () => AccountService;
    public authenticationError = null;
    public email = null;
    public password = null;
    public rememberMe = true;

    public doLogin(): void {
        const data = { username: this.email, password: this.password, rememberMe: this.rememberMe };
        axios
            .post('api/authenticate', data)
            .then(result => {
                const bearerToken = result.headers.authorization;
                if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
                    const jwt = bearerToken.slice(7, bearerToken.length);
                    if (this.rememberMe) {
                        localStorage.setItem('jhi-authenticationToken', jwt);
                        sessionStorage.removeItem('jhi-authenticationToken');
                    } else {
                        sessionStorage.setItem('jhi-authenticationToken', jwt);
                        localStorage.removeItem('jhi-authenticationToken');
                    }
                }
                this.authenticationError = false;
                this.$root.$emit('bv::hide::modal', 'login-page');
                this.accountService().retrieveAccount();
            })
            .catch(() => {
                this.authenticationError = true;
            });
    }
}
