var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-body" }, [
    _c("div", { staticClass: "row justify-content-center" }, [
      _c(
        "div",
        { staticClass: "col-md-8" },
        [
          _c(
            "b-form",
            {
              attrs: { role: "form" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.doSubmit()
                },
              },
            },
            [
              _c(
                "b-form-group",
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "title",
                      type: "text",
                      name: "title",
                      autofocus: "",
                      placeholder: "Teamname",
                      "data-cy": "title",
                    },
                    model: {
                      value: _vm.title,
                      callback: function ($$v) {
                        _vm.title = $$v
                      },
                      expression: "title",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        "data-cy": "submit",
                        type: "submit",
                        variant: "primary",
                        disabled: !_vm.isValidForm,
                      },
                    },
                    [_vm._v("Anlegen ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }