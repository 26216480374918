var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    {
      attrs: {
        "border-variant": "primary",
        header: "Hilfe & Erläuterungen",
        align: "left",
      },
    },
    [
      _c("b-card-text", [
        _c("p", [
          _vm._v(
            "- Das Berühren einer Zahl zeigt die genaue Uhrzeit der Buchung und wer diese gebucht hat."
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "- Das Anklicken einer Zahl öffnet eine Unterseite und zeigt dort sämtliche Einzel-Buchungen dieser Buchung."
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "- Der Buchstabe K vor einem Datum bedeutet, dass dies eine korrigierte Buchung ist."
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }