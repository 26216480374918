import Vue from 'vue';
import Component from 'vue-class-component';
import Ribbon from '@/core/ribbon/ribbon.vue';
import JhiFooter from '@/core/jhi-footer/jhi-footer.vue';
import JhiNavbar from '@/core/jhi-navbar/jhi-navbar.vue';
import LoginForm from '@/account/login-form/login-form.vue';

import '@/shared/config/dayjs';
import BookingService from './konto/services/booking.service';
import { Inject } from 'vue-property-decorator';
import { useBookingsStore } from './konto/stores/booking-store';
import { useBookingBookStore } from './konto/stores/booking-book.store';
import NewBookingBookForm from './konto/new-booking-book/new-booking-book.vue';

@Component({
    components: {
        ribbon: Ribbon,
        'jhi-navbar': JhiNavbar,
        'login-form': LoginForm,
        'jhi-footer': JhiFooter,
        'new-booking-book': NewBookingBookForm,
    },
    metaInfo() {
        return {
            script: [
                {
                    type: 'application/ld+json',
                    json: {
                        '@context': 'https://schema.org',
                        '@type': 'WebPage',
                        name: 'mittagessen-konto.de',
                        description: 'Das praktische Mittagessen-Konto - mittagessen-konto.de',
                    },
                },
            ],
        };
    },
})
export default class App extends Vue {
    @Inject('bookingService') private bookingService: () => BookingService;

    public get authenticated(): boolean {
        return this.$store.getters.authenticated;
    }

    created() {
        if (!this.authenticated) {
            this.bookingService().cleanUpAfterLogout();
        } else {
            if (useBookingBookStore().activeBook == null) {
                this.bookingService().loadActiveBook(useBookingBookStore().activeBook.anonymousAccessPassword);
            }
        }

        this.bookingService().initPrivateBook();

        this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'logout') {
                this.bookingService().cleanUpAfterLogout();
                this.bookingService().initPrivateBook();
                if (this.$route.path != '/') {
                    this.$router.push('/');
                }
            } else if (mutation.type === 'authenticated') {
                this.bookingService().loadDataAfterLogin();
            }
        });
    }
}
