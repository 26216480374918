export interface IBookAccount {
    id?: number;
    title?: string | null;
    creationDateTime?: Date;
    creatorUserId?: number;
}

export class BookAccount implements IBookAccount {
    constructor(public id?: number, public title?: string | null, public creationDateTime?: Date, public creatorUserId?: number) {}
}
