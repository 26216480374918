var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "shadow-sm": true,
        "account-card": true,
        correctionMode: _vm.correctionMode,
        "m-3": true,
        "p-3": true,
        border: true,
        "border-primary": true,
        "bg-light": true,
      },
      on: {
        mouseenter: function ($event) {
          return _vm.enableHighlightCard()
        },
        mouseleave: function ($event) {
          return _vm.disableHighlightCard()
        },
      },
    },
    [
      _c("div", { staticClass: "d-flex justify-content-center m-2" }, [
        _vm.titleEditMode
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.inputTitleFieldValue,
                  expression: "inputTitleFieldValue",
                },
              ],
              attrs: { placeholder: "Name", state: _vm.titleValid },
              domProps: { value: _vm.inputTitleFieldValue },
              on: {
                keyup: function ($event) {
                  return _vm.checkTitle()
                },
                change: function ($event) {
                  return _vm.saveTitle()
                },
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.inputTitleFieldValue = $event.target.value
                },
              },
            })
          : _c(
              "b",
              {
                staticStyle: { "font-size": "1.2rem", "padding-left": "20px" },
              },
              [_vm._v(_vm._s(_vm.account.title))]
            ),
        _vm._v(" "),
        _c(
          "small",
          { staticClass: "p-1", staticStyle: { width: "20px" } },
          [
            _vm.isCardHighlightActive
              ? _c("font-awesome-icon", {
                  attrs: { icon: "fa-regular fa-pen-to-square" },
                  on: {
                    click: function ($event) {
                      return _vm.editTitle()
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      !_vm.correctionMode
        ? _c("div", { staticClass: "text-center m-2" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("konto.current-saldo")) +
                " :\n        "
            ),
            _vm.saldo < 0
              ? _c("span", { staticStyle: { color: "red" } }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("konto.minus-saldo")) +
                      " " +
                      _vm._s(_vm._f("toCurrency")(_vm.saldo))
                  ),
                ])
              : _vm._e(),
            _vm.saldo >= 0
              ? _c("span", { staticStyle: { color: "green" } }, [
                  _vm._v(
                    _vm._s(_vm.$t("konto.plus-saldo")) +
                      " " +
                      _vm._s(_vm._f("toCurrency")(_vm.saldo))
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "text-center my-3" }),
      _vm._v(" "),
      _vm.adblockerStatusStore.adblockerActive && _vm.index > 1
        ? _c("div", { staticClass: "alert alert-danger" }, [
            _vm._v("\n        Bitte deaktivieren Sie Ihren "),
            _c("b", [_vm._v("Adblocker")]),
            _c("br"),
            _vm._v("\n        um alle Funktionen nutzen zu können!\n    "),
          ])
        : _c("div", { staticClass: "account-card-booking-table" }, [
            _c(
              "div",
              { staticClass: "inputlist m-1" },
              [
                _c(
                  "div",
                  {
                    staticClass: "text-center bookingEntry",
                    staticStyle: { height: "69px" },
                    attrs: { id: "tooltip-target-minus-" + _vm.account.id },
                  },
                  [
                    _c("h3", { staticClass: "text-center m-0 mt-2" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("konto.minus")) +
                          "\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._m(0),
                  ]
                ),
                _vm._v(" "),
                _vm.bookingEnabled
                  ? _c(
                      "div",
                      [
                        _c("currency-input", {
                          attrs: { placeholder: "Neuer Eintrag" },
                          on: {
                            change: function ($event) {
                              return _vm.updateBookingBalance()
                            },
                            keyup: function ($event) {
                              return _vm.updateBookingBalance()
                            },
                          },
                          model: {
                            value: _vm.minus1,
                            callback: function ($$v) {
                              _vm.minus1 = $$v
                            },
                            expression: "minus1",
                          },
                        }),
                        _vm._v(" "),
                        _c("currency-input", {
                          attrs: { placeholder: "Neuer Eintrag" },
                          on: {
                            keyup: function ($event) {
                              return _vm.updateBookingBalance()
                            },
                            change: function ($event) {
                              return _vm.updateBookingBalance()
                            },
                          },
                          model: {
                            value: _vm.minus2,
                            callback: function ($$v) {
                              _vm.minus2 = $$v
                            },
                            expression: "minus2",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "TransitionGroup",
                  { attrs: { name: "bookedEntries" } },
                  _vm._l(
                    _vm.minusBookingEntries.slice(
                      0,
                      _vm.bookingOverviewModeLimit
                    ),
                    function (bookingEntry, index) {
                      return _c("booking-entry", {
                        key: bookingEntry.id,
                        class: { spacing: index > 0 && index % 9 == 0 },
                        attrs: {
                          index: index,
                          bookingEntry: bookingEntry,
                          correctionMode: _vm.correctionMode,
                        },
                      })
                    }
                  ),
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "inputlist m-1" },
              [
                _c(
                  "div",
                  {
                    staticClass: "text-center bookingEntry",
                    staticStyle: { height: "69px" },
                    attrs: { id: "tooltip-target-plus-" + _vm.account.id },
                  },
                  [
                    _c("h3", { staticClass: "m-0 mt-2" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("konto.plus")) +
                          "\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "small",
                      {
                        staticClass: "text-center mb-1",
                        staticStyle: { height: "25px", display: "block" },
                      },
                      [_vm._v("(=hat bezahlt)")]
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.bookingEnabled
                  ? _c(
                      "div",
                      [
                        _c("currency-input", {
                          attrs: { placeholder: "Neuer Eintrag" },
                          on: {
                            keyup: function ($event) {
                              return _vm.updateBookingBalance()
                            },
                            change: function ($event) {
                              return _vm.updateBookingBalance()
                            },
                          },
                          model: {
                            value: _vm.plus1,
                            callback: function ($$v) {
                              _vm.plus1 = $$v
                            },
                            expression: "plus1",
                          },
                        }),
                        _vm._v(" "),
                        _c("currency-input", {
                          attrs: { placeholder: "Neuer Eintrag" },
                          on: {
                            keyup: function ($event) {
                              return _vm.updateBookingBalance()
                            },
                            change: function ($event) {
                              return _vm.updateBookingBalance()
                            },
                          },
                          model: {
                            value: _vm.plus2,
                            callback: function ($$v) {
                              _vm.plus2 = $$v
                            },
                            expression: "plus2",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "TransitionGroup",
                  { attrs: { name: "bookedEntries" } },
                  _vm._l(
                    _vm.plusBookingEntries.slice(
                      0,
                      _vm.bookingOverviewModeLimit
                    ),
                    function (bookingEntry, index) {
                      return _c("booking-entry", {
                        key: bookingEntry.id,
                        class: { spacing: index > 0 && index % 9 == 0 },
                        attrs: {
                          index: index,
                          bookingEntry: bookingEntry,
                          correctionMode: _vm.correctionMode,
                        },
                      })
                    }
                  ),
                  1
                ),
              ],
              1
            ),
          ]),
      _vm._v(" "),
      _vm.hasBookingEntriesToHide
        ? _c(
            "div",
            { staticClass: "text-center" },
            [
              !_vm.bookingOverviewMode
                ? _c(
                    "router-link",
                    { attrs: { to: "/pages/bookingoverview" } },
                    [_vm._v("mehr Buchungen anzeigen")]
                  )
                : _c(
                    "a",
                    {
                      staticClass: "primary",
                      on: {
                        click: function ($event) {
                          return _vm.showMore()
                        },
                      },
                    },
                    [_c("span", [_vm._v("mehr Buchungen anzeigen")])]
                  ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "small",
      { staticClass: "text-center mb-1", staticStyle: { display: "block" } },
      [
        _vm._v("\n                    (=empfangene Leistung:"),
        _c("br"),
        _vm._v("\n                    Geld oder Essen)\n                "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }