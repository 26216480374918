/* eslint-disable prettier/prettier */
import { defineStore } from 'pinia';
declare interface BookingModeStoreModel {
    bookingId?: string;
    editModeNew: boolean;
    editModeCorrection: boolean;
    editModeSelectBookingSet: boolean;
}

export const useBookingModeStore = defineStore('BookingModeStore', {
    state: (): BookingModeStoreModel => {
        return {
            editModeNew: false,
            editModeCorrection: false,
            editModeSelectBookingSet: false,
        };
    },
    getters: {
        editingInProgress: state => state.editModeNew || state.editModeCorrection,
    },
    actions: {
        startEditingNewMode() {
            this.editModeNew = true;
            this.editModeCorrection = false;
            this.editModeSelectBookingSet = false;
        },

        selectBookingSet() {
            this.editModeSelectBookingSet = true;
        },

        startEditingNewCorrection() {
            this.editModeNew = false;
            this.editModeSelectBookingSet = false;
            this.editModeCorrection = true;
        },

        stopEditingMode() {
            this.editModeNew = false;
            this.editModeCorrection = false;
            this.editModeSelectBookingSet = false;
        },
        clear() {
            this.editModeNew = false;
            this.editModeCorrection = false;
            this.editModeSelectBookingSet = false;
        },
    },

    persist: false,
});
