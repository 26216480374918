var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "d-sm-none w-100" },
      [
        !_vm.correctionMode
          ? _c("account-overview-card", {
              attrs: { bookingOverviewMode: _vm.bookingOverviewMode },
            })
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "pt-3 d-sm-none",
        staticStyle: { position: "sticky", top: "-30px", "z-index": "2" },
      },
      [
        _c("booking-control-card", {
          attrs: {
            bookingOverviewMode: _vm.bookingOverviewMode,
            correctionMode: _vm.correctionMode,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "col-12 col-sm-6 col-lg-8" }, [
      _c(
        "div",
        { staticClass: "account-cards-container" },
        [
          _vm._l(_vm.accountStore.accounts, function (account, index) {
            return _c("account-card", {
              key: account.id,
              attrs: {
                account: account,
                index: index,
                bookingOverviewMode: _vm.bookingOverviewMode,
                correctionMode: _vm.correctionMode,
                bookingSetId: _vm.bookingSetId,
              },
            })
          }),
          _vm._v(" "),
          _c("ins", {
            staticClass: "adsbygoogle",
            staticStyle: { display: "block", width: "320px" },
            attrs: {
              "data-ad-test": "on",
              "data-ad-format": "fluid",
              "data-ad-layout-key": "-6r+di+5g-2m-8y",
              "data-ad-client": "ca-pub-9421833429411357",
              "data-ad-slot": "5545801451",
            },
          }),
          _vm._v(" "),
          _c("script", { tag: "component" }, [
            _vm._v(" (adsbygoogle = window.adsbygoogle || []).push({}); "),
          ]),
        ],
        2
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-0 col-sm-6 col-lg-4 d-none d-sm-block" }, [
      _c(
        "div",
        {
          staticClass: "pt-3",
          staticStyle: { position: "sticky", top: "30px", "z-index": "2" },
        },
        [
          _c("booking-control-card", {
            attrs: {
              bookingOverviewMode: _vm.bookingOverviewMode,
              correctionMode: _vm.correctionMode,
            },
          }),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("help-card"),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          !_vm.correctionMode
            ? _c("account-overview-card", {
                attrs: { bookingOverviewMode: _vm.bookingOverviewMode },
              })
            : _vm._e(),
          _c("br"),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }