import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import { useBookingModeStore } from '../stores/booking-mode-store';
import { useBookingBalanceStore } from '../stores/booking-balance.store';
import { useBookingsStore } from '../stores/booking-store';
import { IModificationSet, ModificationSet } from '@/shared/model/modification-set.model';
import { useBookingBookStore } from '../stores/booking-book.store';
@Component({
    components: {},
})
export default class BookingEntryComponent extends Vue {
    @Prop()
    bookingEntry: SimpleBookingValueModel;
    @Prop({ default: false })
    correctionMode: boolean;

    inputFieldValue = '';
    editModeEnabled = false;

    bookingModeStore = useBookingModeStore();
    bookingBalanceStore = useBookingBalanceStore();
    bookingStore = useBookingsStore();
    bookingBookStore = useBookingBookStore();
    simpleBookingIds = 'ASD';
    selectedToEdit = false;
    isMarkedCorrected = false;

    mounted() {
        this.bookingModeStore.$subscribe((mutation, state) => {
            // on start of CorrectionEditing copy the Entrybook vale into inputFieldValue
            this.updateInputFieldValue();
        });

        this.bookingBalanceStore.$subscribe((mutation, state) => {
            // id editModeCorrection is enabled
            this.renderEditMode();
        });

        this.updateInputFieldValue();
        this.renderEditMode();
    }

    private updateInputFieldValue() {
        if (!this.isCorrected && this.bookingModeStore.editModeCorrection === true) {
            this.inputFieldValue = this.value.toString();
        }
    }

    private renderEditMode() {
        if (!this.isCorrected && this.bookingModeStore.editModeCorrection === true) {
            // and the booking this enty belongs to is modified

            if (
                this.bookingBalanceStore.modifiedBookings.filter(
                    (simpleBookingEntry: SimpleBookingValueModel) => simpleBookingEntry.bookingId === this.bookingEntry.bookingId
                ).length > 0
            ) {
                // enable modification
                this.editModeEnabled = true;
            } else {
                this.editModeEnabled = false;
            }
        } else {
            this.editModeEnabled = false;
        }
    }

    get isModification() {
        return this.bookingEntry.modificationSetId != null;
    }

    get canAccessModification() {
        return !this.correctionMode && !this.bookingModeStore.editModeSelectBookingSet;
    }

    get isCorrected() {
        return this.bookingEntry.successor != null;
    }

    get value() {
        return this.bookingEntry.plus != null ? this.bookingEntry.plus : this.bookingEntry.minus;
    }

    get isInputFieldEnabled() {
        return this.editModeEnabled && this.selectedToEdit;
    }

    doStartModification() {
        this.inputFieldValue = this.value.toString();
        const booking = this.bookingStore.bookings.find(booking => booking.id === this.bookingEntry.bookingId);
        this.bookingModeStore.startEditingNewCorrection();
        this.bookingBalanceStore.startBookingModification(booking);
        (<any>this).$router.push('/pages/correction/' + this.bookingEntry.bookingId);
    }

    doUpdateBookingBalance() {
        if (this.inputFieldValue === '') {
            this.inputFieldValue = '0';
        }

        const mod = this.bookingBalanceStore.modifiedBookings.find(mod => mod.id === this.bookingEntry.id);

        if (this.bookingEntry.plus != null) mod.plus = Number.parseFloat(this.inputFieldValue);
        if (this.bookingEntry.minus != null) mod.minus = Number.parseFloat(this.inputFieldValue);
    }

    openDetails() {
        if (!this.bookingModeStore.editingInProgress && !this.bookingModeStore.editModeSelectBookingSet) {
            this.$root.$router.push({ path: '/pages/correction/' + this.bookingEntry.bookingId });
        }
    }

    markToCorrect() {
        //this.selectedToEdit = true;
        this.isMarkedCorrected = true;
        const mod = this.bookingBalanceStore.modifiedBookings.find(mod => mod.id === this.bookingEntry.id);

        if (this.bookingEntry.plus != null) mod.plus = 0;
        if (this.bookingEntry.minus != null) mod.minus = 0;
    }

    get canEdit() {
        return !this.isCorrected && this.bookingModeStore.editModeSelectBookingSet;
    }

    formatCurrency(value: string) {
        if (value.indexOf(',') >= 0 || value.indexOf('.') >= 0) {
            return Number.parseFloat(value).toFixed(2);
        }
        return value;
    }
}
