/* eslint-disable prettier/prettier */
import { IBookingBook } from '@/shared/model/booking-book.model';
import { faGrinTongueSquint } from '@fortawesome/free-regular-svg-icons';
import { defineStore } from 'pinia';
import { useAccountStore } from './account-store';

declare interface BookingBookStoreModel {
    books: IBookingBook[];
    activeBook: IBookingBook;
}

export const useBookingBookStore = defineStore('BookingBookStore', {
    state: (): BookingBookStoreModel => {
        return {
            books: [],
            activeBook: undefined,
        };
    },

    getters: {
        hasTeamBooks: state => {
            return state.books.find(book => book.teamBook == true) != null;
        },
    },

    actions: {
        addBooks(bookingBooks: IBookingBook[]) {
            bookingBooks.forEach(book => {
                this.addBook(book);
            });
        },
        addBook(bookingBook: IBookingBook) {
            const existingBookIndex = this.books.findIndex(book => book.id === bookingBook.id);
            if (existingBookIndex >= 0) {
                this.books[existingBookIndex] = bookingBook;
            } else {
                this.books.push(bookingBook);
            }
        },
        setActiveBook(bookId: number) {
            this.activeBook = this.books.find((book: IBookingBook) => book.id === bookId);
            if (this.activeBook?.accounts != null) {
                useAccountStore().setAccounts(this.activeBook.accounts);
            }
        },
        removeTeamBooks() {
            this.books = this.books.filter(book => book.teamBook === false);
            if (this.books.filter(book => book.id == this.activeBook?.id).length == 0) {
                this.activeBook = null;
            }
        },
    },

    persist: true,
});
