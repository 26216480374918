import axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios';

import { useBookingBookStore } from '../stores/booking-book.store';

import { useUserStore } from '../stores/user.store';
import { IBookAccount } from '@/shared/model/book-account.model';
import { IBookingBook } from '@/shared/model/booking-book.model';
import { IBookingSet } from '@/shared/model/booking-set.model';
import { useBookingsStore } from '../stores/booking-store';
import { IModificationSet } from '@/shared/model/modification-set.model';
import { useAccountStore } from '../stores/account-store';
import { useOrderingFavoritesStore } from '@/ordering/stores/ordering-favorites.store';
import OrderingService from '@/ordering/services/ordering.service';
import { useBookingBalanceStore } from '../stores/booking-balance.store';
import { useBookingModeStore } from '../stores/booking-mode-store';
const baseApiUrl = 'api/booking';
export default class BookingService {
    private axios: AxiosInstance;
    private orderingService: OrderingService;

    bookingBookStore = useBookingBookStore();
    bookingAccountStore = useAccountStore();
    bookingStore = useBookingsStore();
    favoritesStore = useOrderingFavoritesStore();
    activeBookingBookId: number = null;
    constructor() {
        this.axios = axios;
        //this.orderingService = orderingService;

        /*
        this.activeBookingBookId = this.bookingBookStore.activeBook?.id;
        this.bookingBookStore.$subscribe((mutation, state) => {
            if (state.activeBook.id !== this.activeBookingBookId) {
                this.loadActiveBook(state.activeBook.id).then(bookingBook => {
                    this.activeBookingBookId = bookingBook.id;
                });
            }
        });
        */
    }

    public loadActiveBook(bookAccessToken: string) {
        const privateBookAccessToken = useUserStore().privateAccessToken;
        const result: Promise<IBookingBook> = (
            privateBookAccessToken != null && privateBookAccessToken == bookAccessToken
                ? this.loadPrivateBookData()
                : this.loadTeamBookData(bookAccessToken)
        ).then(response => {
            const bookingBook = response.data as IBookingBook;
            useBookingBookStore().addBook(bookingBook);
            useBookingBookStore().setActiveBook(bookingBook.id);
            useBookingBalanceStore().clear();
            useBookingModeStore().clear();
            const bookingSetPromise = this.loadBookingSets(bookingBook.id);
            const modificationSetPromise = this.loadModificationSets(bookingBook.id);
            Promise.all([bookingSetPromise, modificationSetPromise]).then(results => {
                const bookings = results[0];
                bookings.forEach(bookingSet => bookingSet.entries.forEach(entry => (entry.bookingSet = bookingSet)));
                const modifications = results[1];
                useBookingsStore().initWithBookData(bookings, modifications);
            });

            return bookingBook;
        });

        return result;
    }

    public loadDataAfterLogin() {
        this.bookingBookStore.removeTeamBooks();
        const params = { privateAccessToken: useUserStore().privateAccessToken };
        const config: AxiosRequestConfig = { params };
        return axios
            .get(`${baseApiUrl}/booking-books/`, config)
            .then(response => {
                const bookingBooks = response.data as IBookingBook[];
                return bookingBooks;
            })
            .then(books => {
                this.bookingBookStore.addBooks(books);
                if (this.bookingBookStore.books.find(book => book.id === this.bookingBookStore.activeBook?.id) == null) {
                    const leftBookAvailableId = this.bookingBookStore.books.length > 0 ? this.bookingBookStore.books[0].id : null;
                    this.bookingBookStore.setActiveBook(leftBookAvailableId);
                }
            });
    }

    public cleanUpAfterLogout() {
        if (this.bookingBookStore.hasTeamBooks) {
            this.bookingBookStore.removeTeamBooks();
            this.bookingAccountStore.clear();
            this.bookingStore.clear();
            this.favoritesStore.clear();
        }
    }

    public initPrivateBook() {
        const privateBook = this.bookingBookStore.books.find(book => !book.teamBook);

        if (privateBook) {
            this.loadActiveBook(privateBook.anonymousAccessPassword);
        }

        // add additional accounts if necessary
        this.bookingAccountStore.init();
    }

    public loadPrivateBookData(): Promise<AxiosResponse<IBookingBook>> {
        const params = { privateAccessToken: useUserStore().privateAccessToken };
        const config: AxiosRequestConfig = { params };

        const bookId = useBookingBookStore().books.find(book => book.anonymousAccessPassword == useUserStore().privateAccessToken)?.id;
        return axios.get(`${baseApiUrl}/booking-books/${bookId}`, config);
    }

    public loadTeamBookData(anonymousAccessPassword: string): Promise<AxiosResponse<IBookingBook>> {
        const params = { privateAccessToken: useUserStore().privateAccessToken };
        const config: AxiosRequestConfig = { params };
        return axios.get(`${baseApiUrl}/booking-books/team/${anonymousAccessPassword}`, config);
    }

    public loadBookingSets(bookId: number) {
        const params = { privateAccessToken: useUserStore().privateAccessToken };
        const config: AxiosRequestConfig = { params };
        return axios.get(`${baseApiUrl}/booking-books/${bookId}/booking-sets`, config).then(response => {
            const bookingSets = response.data as IBookingSet[];
            return bookingSets;
        });
    }

    public loadModificationSets(bookId: number) {
        const params = { privateAccessToken: useUserStore().privateAccessToken };
        const config: AxiosRequestConfig = { params };
        return axios.get(`${baseApiUrl}/booking-books/${bookId}/modification-sets`, config).then(response => {
            const modificationSets = response.data as IBookingSet[];
            return modificationSets;
        });
    }

    public createBooking(bookingSet: IBookingSet): Promise<IBookingSet> {
        if (this.bookingBookStore.activeBook !== undefined) {
            const params = { privateAccessToken: useUserStore().privateAccessToken };
            const config: AxiosRequestConfig = { params };
            return axios.post(`${baseApiUrl}/booking-set`, bookingSet, config).then(response => {
                const bookingSet = response.data as IBookingSet;

                bookingSet.entries.forEach(entry => (entry.bookingSet = bookingSet));
                this.bookingStore.addEntry(bookingSet);
                return bookingSet;
            });
        } else {
            return Promise.reject('Unexpected Error');
        }
    }

    public createModification(modificationSet: IModificationSet): Promise<IBookingSet> {
        if (this.bookingBookStore.activeBook !== undefined) {
            // clear sucessor and predecessor to avoid circular dependencies.
            modificationSet.entries.forEach(entry => {
                entry.successor = undefined;
                entry.predecessor = undefined;
            });

            const params = { privateAccessToken: useUserStore().privateAccessToken };
            const config: AxiosRequestConfig = { params };

            return axios.post(`${baseApiUrl}/modification-sets`, modificationSet, config).then(response => {
                const modSet = response.data as IModificationSet;

                const bookingSet = this.bookingStore.bookings.find(booking => booking.id === modSet.bookingSetId);
                modSet.entries.forEach(entry => {
                    entry.bookingSet = bookingSet;
                    entry.modificationSetId = modSet.id;
                });
                this.bookingStore.addModification(modSet);
                return modSet;
            });
        } else {
            return Promise.reject('Unexpected Error');
        }
    }

    public updateBookingAccount(account: IBookAccount): Promise<IBookingBook> {
        if (this.bookingBookStore.activeBook == null) {
            const createBookingPromise = this.createBookingBook(undefined).then(book => {
                this.bookingBookStore.setActiveBook(book.id);

                return this.createBookingAccount(account.title, book.id);
            });
            return createBookingPromise;
        } else {
            if (account.id) {
                return this.updateBookingAccountTitle(account);
            } else {
                return this.createBookingAccount(account.title, this.bookingBookStore.activeBook.id).then(accountEntity => {
                    account.id = accountEntity.id;
                    return accountEntity;
                });
            }
        }
    }

    public createBookingBook(title: string): Promise<IBookingBook> {
        const params = { privateAccessToken: useUserStore().privateAccessToken };
        const config: AxiosRequestConfig = { params };

        return axios.post(`${baseApiUrl}/booking-books`, { title }, config).then(response => {
            this.bookingBookStore.addBook(response.data);
            return response.data;
        });
    }

    public createBookingAccount(title: string, bookId: number): Promise<IBookAccount> {
        const params = { privateAccessToken: useUserStore().privateAccessToken };
        const config: AxiosRequestConfig = { params };

        return axios
            .post(
                `${baseApiUrl}/book-accounts`,
                {
                    id: undefined,
                    bookingBookId: bookId,
                    title,
                },
                config
            )
            .then(response => {
                return response.data;
            });
    }

    public updateBookingAccountTitle(account: IBookAccount): Promise<IBookAccount> {
        const params = { privateAccessToken: useUserStore().privateAccessToken };
        const config: AxiosRequestConfig = { params };

        return axios.patch(`${baseApiUrl}/book-accounts/${account.id}`, account, config).then(response => {
            return response.data;
        });
    }
}
