import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import { useBookingBalanceStore } from '../stores/booking-balance.store';
import { useBookingModeStore } from '../stores/booking-mode-store';
import { useBookingsStore } from '../stores/booking-store';
import { useUserStore } from '../stores/user.store';
@Component
export default class HelpComponent extends Vue {
    accordion1 = false;
    accordion2 = false;

    text = 'adsasd';
}
