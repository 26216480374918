import Component from 'vue-class-component';
import { Inject, Prop, Vue } from 'vue-property-decorator';
import AccountCardComponent from '../account-card/account-card.vue';

import AccountOverviewCardComponent from '../accounts-overview-card/account-overview-card.vue';
import BookingControlCardComponent from '../booking-control-card/booking-control-card.vue';
import { useAccountStore } from '../stores/account-store';
import { useBookingsStore } from '../stores/booking-store';
import { useBookingModeStore } from '../stores/booking-mode-store';
import HelpComponent from '../help/help.vue';
import { useBookingBookStore } from '../stores/booking-book.store';
import BookingService from '../services/booking.service';
@Component({
    components: {
        'account-card': AccountCardComponent,
        'account-overview-card': AccountOverviewCardComponent,
        'booking-control-card': BookingControlCardComponent,
        'help-card': HelpComponent,
    },
})
export default class AccountComponent extends Vue {
    @Inject('bookingService') private bookingService: () => BookingService;

    @Prop({ default: false })
    bookingOverviewMode: boolean;
    @Prop({ default: null })
    bookingSetId: number;
    @Prop()
    correctionMode: boolean;

    accountStore = useAccountStore();

    created() {}
}
