var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isInputFieldEnabled
        ? _c("b-form-input", {
            attrs: {
              size: "sm",
              type: "number",
              min: "0.00",
              step: "0.01",
              placeholder: "Neuer Eintrag",
            },
            on: {
              keyup: function ($event) {
                return _vm.doUpdateBookingBalance()
              },
              change: function ($event) {
                return _vm.updateBookingBalance()
              },
            },
            model: {
              value: _vm.inputFieldValue,
              callback: function ($$v) {
                _vm.inputFieldValue = $$v
              },
              expression: "inputFieldValue",
            },
          })
        : _c(
            "div",
            { class: { "text-center": true, bookingEntry: true } },
            [
              _c(
                "div",
                {
                  attrs: { id: "tooltip-target-" + _vm.bookingEntry.id },
                  on: {
                    click: function ($event) {
                      return _vm.openDetails()
                    },
                  },
                },
                [
                  _c(
                    "b",
                    {
                      class: {
                        corrected: _vm.isCorrected || _vm.isMarkedCorrected,
                        modification: _vm.isModification && _vm.correctionMode,
                      },
                    },
                    [_vm._v(_vm._s(_vm._f("toCurrency")(_vm.value)))]
                  ),
                  _vm._v(" "),
                  _vm.canEdit
                    ? _c(
                        "small",
                        {
                          staticClass: "p-1",
                          staticStyle: { width: "20px" },
                          on: {
                            click: function ($event) {
                              return _vm.doStartModification()
                            },
                          },
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.doStartModification()
                                },
                              },
                            },
                            [_vm._v("Buchung korrigieren")]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _vm.isModification ? _c("small", [_vm._v(" K ")]) : _vm._e(),
                  _vm._v(" "),
                  _vm.bookingEntry.date
                    ? _c("small", [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm._f("formatDate")(_vm.bookingEntry.date)
                            ) +
                            "\n            "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.editModeEnabled && !_vm.isMarkedCorrected
                    ? _c("small", [
                        _vm._v("/\n                "),
                        _c(
                          "a",
                          {
                            staticClass: "primary",
                            on: { click: _vm.markToCorrect },
                          },
                          [_vm._v(" korrigieren ")]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.bookingEntry.modificationDate
                    ? _c("small", [
                        _vm._v(
                          " / K " +
                            _vm._s(
                              _vm._f("formatDate")(
                                _vm.bookingEntry.modificationDate
                              )
                            ) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-tooltip",
                {
                  attrs: {
                    target: "tooltip-target-" + _vm.bookingEntry.id,
                    triggers: "hover",
                    placement: "topright",
                  },
                },
                [
                  _c("div", { staticClass: "bookingEntryTooltip" }, [
                    _c("b", [_vm._v(_vm._s(_vm._f("toCurrency")(_vm.value)))]),
                    _c("br"),
                    _vm._v(" "),
                    _vm.isModification
                      ? _c("span", [
                          _vm._v(
                            "Korrektur gebucht am " +
                              _vm._s(
                                _vm._f("formatDate")(_vm.bookingEntry.date)
                              ) +
                              " (" +
                              _vm._s(
                                _vm._f("formatTime")(_vm.bookingEntry.date)
                              ) +
                              "Uhr)\n                "
                          ),
                        ])
                      : _c("span", [
                          _vm._v(
                            "gebucht am " +
                              _vm._s(
                                _vm._f("formatDate")(_vm.bookingEntry.date)
                              ) +
                              " (" +
                              _vm._s(
                                _vm._f("formatTime")(_vm.bookingEntry.date)
                              ) +
                              "Uhr)"
                          ),
                        ]),
                    _vm._v(" "),
                    _vm.bookingEntry.user
                      ? _c("span", [
                          _vm._v(" von " + _vm._s(_vm.bookingEntry.user)),
                          _c("br"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.bookingEntry.modificationDate
                      ? _c("span", [
                          _vm._v(
                            "\n                    korrigiert am " +
                              _vm._s(
                                _vm._f("formatDate")(
                                  _vm.bookingEntry.modificationDate
                                )
                              ) +
                              " (" +
                              _vm._s(
                                _vm._f("formatTime")(
                                  _vm.bookingEntry.modificationDate
                                )
                              ) +
                              "Uhr)\n                    "
                          ),
                          _c("span", [
                            _vm._v(
                              " von " +
                                _vm._s(_vm.bookingEntry.modificationUser)
                            ),
                            _c("br"),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ]
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }