import { useBookingBookStore } from '@/konto/stores/booking-book.store';
import Component from 'vue-class-component';
import { Inject, Prop, Vue } from 'vue-property-decorator';
import OrderingService from '../services/ordering.service';
import { useOrderingFavoritesStore } from './../stores/ordering-favorites.store';

@Component
export default class OrderingCardComponent extends Vue {
    @Inject('orderingService') private orderingService: () => OrderingService;
    orderingFavoritesStore = useOrderingFavoritesStore();

    inputFavoriteUrlFieldValue = '';
    inputFavoriteNameFieldValue = '';
    inputFavoritePhoneFieldValue = '';
    inputFavoriteNotesFieldValue = '';

    modInputFavoriteUrlFieldValue = '';
    modInputFavoriteNameFieldValue = '';
    modInputFavoritePhoneFieldValue = '';
    modInputFavoriteNotesFieldValue = '';

    urlValid = false;
    modUrlValid = false;

    modified = false;

    editFavoriteId: number = null;
    bookingBookStore = useBookingBookStore();

    private bookingStoreUnsubscribe;
    private loadedBookId;

    created() {
        this.loadFavorites();
        this.bookingStoreUnsubscribe = this.bookingBookStore.$subscribe((mutation, state) => {
            this.loadFavorites();
        });
    }

    beforeDestroy() {
        this.bookingStoreUnsubscribe();
    }

    private loadFavorites() {
        if (this.bookingBookStore.activeBook && this.loadedBookId != this.bookingBookStore.activeBook?.id) {
            this.orderingService()
                .loadFavoritesForBook(this.bookingBookStore.activeBook.id)
                .then(restaurantEntries => {
                    this.orderingFavoritesStore.setFavorite(restaurantEntries);
                    this.loadedBookId = this.bookingBookStore.activeBook.id;
                });
        }
    }

    doAddFavorite() {
        let url = this.inputFavoriteUrlFieldValue.trim();
        url = url.startsWith('http') ? url : 'http://' + url;

        this.orderingService()
            .createFavorite({
                title: this.inputFavoriteNameFieldValue.trim(),
                url,
                phone: this.inputFavoritePhoneFieldValue.trim(),
                notes: this.inputFavoriteNotesFieldValue.trim(),
            })
            .then(favorite => {
                this.inputFavoriteUrlFieldValue = '';
                this.inputFavoriteNameFieldValue = '';
                this.inputFavoritePhoneFieldValue = '';
                this.inputFavoriteNotesFieldValue = '';
            });
    }

    checkModifiedUrl() {
        if (
            this.modInputFavoriteUrlFieldValue.trim() !== '' &&
            this.modInputFavoriteNameFieldValue.trim() !== '' &&
            this.validUrl(this.modInputFavoriteUrlFieldValue.trim())
        ) {
            this.modUrlValid = true;
        } else {
            this.modUrlValid = false;
        }
        this.modified = true;
    }

    checkUrl() {
        if (
            this.inputFavoriteUrlFieldValue.trim() !== '' &&
            this.inputFavoriteNameFieldValue.trim() !== '' &&
            this.validUrl(this.inputFavoriteUrlFieldValue.trim())
        ) {
            this.urlValid = true;
        } else {
            this.urlValid = false;
        }
    }

    deleteFavorite(id: number) {
        this.orderingService().deleteFavorite(this.editFavoriteId);
        this.editFavoriteId = null;
        this.modified = false;
    }

    editFavorite(id: number) {
        this.editFavoriteId = id;

        const fav = this.orderingFavoritesStore.favorites.find(f => f.id === id);

        this.modInputFavoriteUrlFieldValue = fav.url;
        this.modInputFavoriteNameFieldValue = fav.title;
        this.modInputFavoritePhoneFieldValue = fav.phone;
        this.modInputFavoriteNotesFieldValue = fav.notes;

        this.checkModifiedUrl();
        this.modified = false;
    }

    cancelEdit() {
        this.modified = false;
        this.editFavoriteId = null;
    }

    saveFavorite() {
        let url = this.modInputFavoriteUrlFieldValue.trim();
        url = url.startsWith('http') ? url : 'http://' + url;

        this.orderingService()
            .updateFavorite({
                id: this.editFavoriteId,
                title: this.modInputFavoriteNameFieldValue.trim(),
                url,
                phone: this.modInputFavoritePhoneFieldValue.trim(),
                notes: this.modInputFavoriteNotesFieldValue.trim(),
            })
            .then(favorite => {
                this.editFavoriteId = null;
                this.modified = false;
            });
    }

    validUrl(url) {
        const re = /^(http:\/\/|https:\/\/)?(www\.)?[a-z0-9\-\.]*?\.[a-z]{2,3}\/?/;
        return re.test(url);
    }
}
