import Component from 'vue-class-component';
import { Inject, Prop, Vue } from 'vue-property-decorator';
import { useBookingsStore } from '../stores/booking-store';
import { useBookingModeStore } from '../stores/booking-mode-store';
import { useBookingBalanceStore } from '../stores/booking-balance.store';
import { useAdblockDetectorStore } from '@/core/adblockdetector/abocker-detector.store';
import BookingEntryComponent from '../booking-entry/booking-entry.vue';
import BookingService from '../services/booking.service';
import AlertService from '@/shared/alert/alert.service';
import AnonymousUserLoginService from '../services/user-login.service';
import { BookAccount } from '@/shared/model/book-account.model';
import { IBookingEntry } from '@/shared/model/booking-entry.model';
import { useAccountStore } from '../stores/account-store';
import { useBookingBookStore } from '../stores/booking-book.store';
@Component({
    components: {
        'booking-entry': BookingEntryComponent,
    },
})
export default class AccountCardComponent extends Vue {
    @Inject('alertService') private alertService: () => AlertService;
    @Inject('bookingService') private bookingService: () => BookingService;
    @Inject('userLoginService') private userLoginService: () => AnonymousUserLoginService;

    @Prop()
    account: BookAccount;
    @Prop()
    index: number;
    @Prop()
    bookingOverviewMode: boolean;
    @Prop()
    correctionMode: boolean;

    @Prop()
    bookingSetId: number;

    bookingModeStore = useBookingModeStore();
    bookingBalanceStore = useBookingBalanceStore();
    adblockerStatusStore = useAdblockDetectorStore();
    bookingStore = useBookingsStore();

    i18nEnabled = true;
    minus1: number = null;
    minus2: number = null;
    plus1: number = null;
    plus2: number = null;
    saldo = 0;
    plusBookingEntries: SimpleBookingValueModel[] = [];
    minusBookingEntries: SimpleBookingValueModel[] = [];
    inputTitleFieldValue = '';
    titleEditMode = false;
    isCardHighlightActive = false;
    hasBookingEntriesToHide = false;
    formatter = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' });
    inputFieldFormatter = new Intl.NumberFormat('de-DE', { maximumFractionDigits: 2, minimumIntegerDigits: 1 });

    titleValid = false;

    bookingOverviewModeLimit = 5;

    get bookingEnabled() {
        return this.bookingModeStore.editingInProgress && this.titleValid;
    }

    mounted() {
        this.correctionMode ? this.renderModifcationEntries() : this.renderBookEntries();
        this.calculateSaldo();
        this.checkBookingsHidden();
        this.checkTitle();

        this.titleEditMode = !this.titleValid;

        // register to Booking store to update displayed Bookings on changes
        useBookingsStore().$subscribe((mutation, state) => {
            this.correctionMode ? this.renderModifcationEntries() : this.renderBookEntries();
            this.calculateSaldo();
            this.checkBookingsHidden();
        });

        this.bookingModeStore.$subscribe((mutation, state) => {
            if (!this.bookingModeStore.editingInProgress) {
                this.reset();
            }
        });

        if (this.bookingOverviewMode) {
            this.showMore();
        }
    }

    updateBookingBalance(event) {
        this.bookingBalanceStore.updateOpenBookingsForAccount({
            accountId: this.account.id,
            minus1: this.minus1,
            minus2: this.minus2,
            plus1: this.plus1,
            plus2: this.plus2,
        });
    }

    enableHighlightCard() {
        this.isCardHighlightActive = true;
    }

    disableHighlightCard() {
        this.isCardHighlightActive = false;
    }

    checkBookingsHidden() {
        const limit = this.bookingOverviewMode ? this.bookingOverviewModeLimit : 5;
        this.hasBookingEntriesToHide = this.plusBookingEntries.length > limit || this.minusBookingEntries.length > limit;
    }

    calculateSaldo() {
        const minusSum = this.minusBookingEntries
            .filter(entry => entry.successor == null)
            .map(entry => entry.minus)
            .reduce((sum, current) => sum + current, 0);
        const plusSum = this.plusBookingEntries
            .filter(entry => entry.successor == null)
            .map(entry => entry.plus)
            .reduce((sum, current) => sum + current, 0);
        this.saldo = plusSum - minusSum;
    }

    editTitle() {
        this.inputTitleFieldValue = this.account.title;
        this.titleEditMode = true;
    }

    checkTitle() {
        if (this.titleEditMode) {
            this.titleValid = this.inputTitleFieldValue.trim() !== '';
        } else {
            this.titleValid = this.account.title !== '';
        }
    }

    saveTitle() {
        if (this.titleValid) {
            const sameTitleAccount = useAccountStore()
                .accounts.filter(account => account.id !== this.account?.id)
                .find(account => account.title.trim().toLowerCase() === this.inputTitleFieldValue.trim().toLowerCase());

            if (!sameTitleAccount) {
                const accountData = { ...this.account, title: this.inputTitleFieldValue };
                this.userLoginService()
                    .initUser()
                    .then(response => {
                        this.bookingService()
                            .updateBookingAccount(accountData)
                            .then(accountEntity => {
                                this.account.title = this.inputTitleFieldValue;
                                this.account.id = accountEntity.id;
                                this.titleEditMode = false;
                            })
                            .catch(errorResponse => {
                                this.alertService().showHttpError(this, errorResponse.response);
                            });
                    })
                    .catch(errorResponse => {
                        this.alertService().showHttpError(this, errorResponse.response);
                    });
            } else {
                this.$root.$bvToast.toast('Account mit dem gleichen Namen existiert bereits', {
                    toaster: 'b-toaster-top-center',
                    title: 'Info',
                    variant: 'danger',
                    solid: true,
                    autoHideDelay: 5000,
                });
            }
        }
    }

    renderModifcationEntries() {
        const bookingSet = useBookingsStore().bookings.find(bookingSet => bookingSet.id === this.bookingSetId);

        const allAccountBookingEntries = bookingSet?.entries
            .filter(entry => entry.bookingAccountId === this.account.id)
            .reduce((resultList: IBookingEntry[], entry: IBookingEntry) => {
                resultList.push(entry);
                while (entry.predecessor) {
                    resultList.push(entry.predecessor);
                    entry = entry.predecessor;
                }
                return resultList;
            }, [])
            .filter(entry => entry.plusValue !== 0 && entry.minusValue !== 0);

        this.convertToSimpleEntriesForMinusAndPlus(allAccountBookingEntries ? allAccountBookingEntries : [], false);
    }

    renderBookEntries() {
        const allBookings = useBookingsStore().bookings;
        const allAccountBookingEntries = allBookings
            .map(booking => booking.entries.filter(entry => entry.bookingAccountId === this.account.id))
            .reduce((resultList: [], current: []) => {
                return [...resultList, ...current];
            }, [])
            .filter(entry => entry.plusValue !== 0 && entry.minusValue !== 0);
        this.convertToSimpleEntriesForMinusAndPlus(allAccountBookingEntries, false);
    }

    private convertToSimpleEntriesForMinusAndPlus(allAccountBookingEntries: IBookingEntry[], includeHistory: boolean) {
        this.minusBookingEntries = allAccountBookingEntries
            .filter(entry => entry.minusValue != null)
            .map(entry => this.mapToSimpleEntry(entry))
            .reduce((resultArray, current) => {
                resultArray.push(current);

                if (includeHistory) {
                    let entryHistory = current;
                    while (entryHistory.predecessor) {
                        resultArray.push(entryHistory.predecessor);
                        entryHistory = entryHistory.predecessor;
                    }
                }

                return resultArray;
            }, [])
            .sort((a: SimpleBookingValueModel, b: SimpleBookingValueModel) => {
                return a.date > b.date ? -1 : a.date < b.date ? 1 : 0;
            });
        this.plusBookingEntries = allAccountBookingEntries
            .filter(entry => entry.plusValue != null)
            .map(entry => this.mapToSimpleEntry(entry))
            .reduce((resultArray, current) => {
                resultArray.push(current);

                if (includeHistory) {
                    let entryHistory = current;
                    while (entryHistory.predecessor) {
                        resultArray.push(entryHistory.predecessor);
                        entryHistory = entryHistory.predecessor;
                    }
                }

                return resultArray;
            }, [])
            .sort((a: SimpleBookingValueModel, b: SimpleBookingValueModel) => {
                return a.date > b.date ? -1 : a.date < b.date ? 1 : 0;
            });
    }

    private mapToSimpleEntry(entry: IBookingEntry): SimpleBookingValueModel {
        if (entry == null) return undefined;
        const bookingSet = this.bookingStore.bookings.find(booking => booking.id === entry.bookingSet.id);
        const bookingSetdate = entry.modificationSetId
            ? this.bookingStore.modifications.find(mod => mod.id === entry.modificationSetId).creationDateTime
            : bookingSet.creationDateTime;

        const creatorUserId = entry.modificationSetId
            ? this.bookingStore.modifications.find(mod => mod.id === entry.modificationSetId).creatorUserId
            : bookingSet.creatorUserId;
        const user = useBookingBookStore().activeBook.users?.find(user => user.id == creatorUserId);

        const modificationSet = entry.successor?.modificationSetId
            ? this.bookingStore.modifications.find(mod => mod.id === entry.successor.modificationSetId)
            : null;
        let modificationSetDate;
        let modificationUser;
        if (modificationSet) {
            modificationSetDate = modificationSet.creationDateTime;
            modificationUser = useBookingBookStore().activeBook.users?.find(user => user.id == modificationSet.creatorUserId);
        }

        const simple: SimpleBookingValueModel = {
            id: entry.id,
            plus: entry.plusValue,
            minus: entry.minusValue,
            bookingId: entry.bookingSet.id,
            date: bookingSetdate,
            modificationDate: modificationSetDate,
            predecessor: entry.predecessor,
            successor: entry.successor,
            modificationSetId: entry.modificationSetId,
            user: user?.login,
            modificationUser: modificationUser?.login,
        };

        return simple;
    }

    showMore() {
        this.bookingOverviewModeLimit += 20;
        this.checkBookingsHidden();
    }

    reset() {
        this.minus1 = null;
        this.minus2 = null;
        this.plus1 = null;
        this.plus2 = null;
    }
}
