/* eslint-disable prettier/prettier */
import { IAnonymousBookingUser } from '@/shared/model/booking-user.model';
import { IUser } from '@/shared/model/user.model';
import { defineStore } from 'pinia';
declare interface UserStoreModel {
    userId: number;
    privateAccessToken: string;
}

export const useUserStore = defineStore('UserStore', {
    state: (): UserStoreModel => {
        return {
            userId: undefined,
            privateAccessToken: undefined,
        };
    },
    actions: {
        setAnonymousUserdata(user: IAnonymousBookingUser) {
            this.userId = user.id;
            this.privateAccessToken = user.privateAccessToken;
        },
    },
    persist: true,
});
