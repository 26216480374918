/* eslint-disable prettier/prettier */
import { IRestaurantEntry } from '@/shared/model/restaurant-entry.model';
import { defineStore } from 'pinia';

declare interface OrderingFavoritesStoreModel {
    favorites: IRestaurantEntry[];
}

export const useOrderingFavoritesStore = defineStore('OrderingFavorites', {
    state: (): OrderingFavoritesStoreModel => {
        return {
            favorites: [],
        };
    },

    actions: {
        clear() {
            this.favorites = [];
        },
        addNewFavorite(entry: IRestaurantEntry) {
            this.favorites.push(entry);
        },
        deleteFavorite(id: number) {
            const renmainingFavorites = this.favorites.filter(fav => fav.id !== id);
            this.favorites = renmainingFavorites;
        },
        setFavorite(entries: IRestaurantEntry[]) {
            this.favorites = entries;
        },

        putFavorite(entry: IRestaurantEntry) {
            const index = this.favorites.findIndex(fav => fav.id === entry.id);
            this.favorites[index] = entry;
        },
    },

    persist: true,
});
