/* eslint-disable prettier/prettier */
import { defineStore } from 'pinia';

declare interface AdblockDetectorStoreModel {
    adblockerActive: boolean;
}

export const useAdblockDetectorStore = defineStore('AdblockDetecotorStore', {
    state: (): AdblockDetectorStoreModel => {
        return {
            adblockerActive: false,
        };
    },

    actions: {
        setAdblockerStatus(status: boolean) {
            this.adblockerActive = status;
        },
    },

    persist: false,
});
