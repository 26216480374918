/* eslint-disable prettier/prettier */
import { IBookingSet } from '@/shared/model/booking-set.model';
import { defineStore } from 'pinia';
import AccountCardComponent from '../account-card/account-card.component';

declare interface BookingBalanceStoreModel {
    openNewBookings: OpenAccountBooking[];
    modifiedBookings: SimpleBookingValueModel[];
}

export const useBookingBalanceStore = defineStore('BookingBalanceStore', {
    state: (): BookingBalanceStoreModel => {
        return {
            modifiedBookings: [],
            openNewBookings: [],
        };
    },

    actions: {
        addEntry(entry) {
            this.bookings.unshift(entry);
        },
        updateOpenBookingsForAccount(openAccountbooking: OpenAccountBooking) {
            let accountBooking = this.openNewBookings.find(accountBookings => accountBookings.accountId === openAccountbooking.accountId);
            if (!accountBooking) {
                this.openNewBookings.push(openAccountbooking);
                accountBooking = openAccountbooking;
            }

            accountBooking.minus1 =
                Number.isNaN(openAccountbooking.minus1) || openAccountbooking.minus1 == null ? 0 : openAccountbooking.minus1;
            accountBooking.minus2 =
                Number.isNaN(openAccountbooking.minus2) || openAccountbooking.minus2 == null ? 0 : openAccountbooking.minus2;
            accountBooking.plus1 =
                Number.isNaN(openAccountbooking.plus1) || openAccountbooking.plus1 == null ? 0 : openAccountbooking.plus1;
            accountBooking.plus2 =
                Number.isNaN(openAccountbooking.plus2) || openAccountbooking.plus2 == null ? 0 : openAccountbooking.plus2;
        },
        startBookingModification(bookingEntry: IBookingSet) {
            this.modifiedBookings = bookingEntry.entries
                .filter(entry => entry.successorId == null)
                .map(entry => {
                    const simple: SimpleBookingValueModel = {
                        id: entry.id,
                        bookingId: entry.bookingSet.id,
                        minus: entry.minusValue,
                        plus: entry.plusValue,
                        date: entry.bookingSet.creationDateTime,
                    };
                    return simple;
                });
        },
        clear() {
            this.openNewBookings = [];
            this.modifiedBookings = [];
        },
    },

    persist: false,
});
