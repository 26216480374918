import BookingUserService from '@/entities/booking-user/booking-user.service';
import { IAnonymousBookingUser } from '@/shared/model/booking-user.model';
import axios, { AxiosInstance, AxiosPromise } from 'axios';
import { Inject } from 'vue-property-decorator';

import { useUserStore } from '../stores/user.store';

const baseApiUrl = 'api/booking/booking-users';
export default class AnonymousUserLoginService {
    private axios: AxiosInstance;

    constructor() {
        this.axios = axios;
    }

    public initUser(): Promise<IAnonymousBookingUser> {
        if (useUserStore().privateAccessToken === undefined) {
            return axios.post(`${baseApiUrl}`, {}).then(response => {
                const userModel = response.data as IAnonymousBookingUser;
                useUserStore().setAnonymousUserdata(userModel);
                return userModel;
            });
        } else {
            return Promise.resolve(useUserStore());
            /*
            return axios.get(`${baseApiUrl}/${useUserStore().userId}`).then(response => {
                const userModel = response.data as IBookingUser;
                useUserStore().email = userModel.email;
                return userModel;
            });
            */
        }
    }
}
