var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    {
      attrs: {
        "border-variant": "primary",
        "header-text-variant": "white",
        align: "left",
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                !_vm.bookingOverviewMode &&
                !_vm.correctionMode &&
                !_vm.isEditInProgress,
              expression:
                "!bookingOverviewMode && !correctionMode && !isEditInProgress",
            },
          ],
          staticClass: "accountOverviewEntry",
        },
        [
          _c(
            "b-button",
            {
              staticClass: "m-2",
              attrs: {
                type: "button",
                variant: "primary",
                disabled: _vm.bookingModeStore.editingInProgress,
              },
              on: {
                click: function ($event) {
                  return _vm.doStartNewBooking()
                },
              },
            },
            [_vm._v("Neue Buchung hinzufügen")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.bookingOverviewMode ||
                _vm.correctionMode ||
                _vm.isEditInProgress,
              expression:
                "bookingOverviewMode || correctionMode || isEditInProgress",
            },
          ],
          staticClass: "accountOverviewEntry",
        },
        [
          _c(
            "b-button",
            {
              staticClass: "m-2",
              attrs: { type: "button", variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.goBack()
                },
              },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.canCancel,
                      expression: "canCancel",
                    },
                  ],
                },
                [_vm._v("Abbrechen und ")]
              ),
              _vm._v("Zurück"),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "accountOverviewEntry" },
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.hasBookings && !_vm.correctionMode,
                  expression: "hasBookings && !correctionMode",
                },
              ],
              staticClass: "m-2",
              attrs: {
                type: "button",
                size: "sm",
                variant: "danger",
                disabled:
                  _vm.bookingModeStore.editingInProgress || _vm.canCancel,
              },
              on: {
                click: function ($event) {
                  return _vm.doStartCorrectionBooking()
                },
              },
            },
            [_vm._v("\n            Korrektur-Buchung\n        ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.bookingModeStore.editingInProgress,
              expression: "bookingModeStore.editingInProgress",
            },
          ],
          staticClass: "accountOverviewEntry",
        },
        [
          _c("div", { staticClass: "m-2" }, [
            _vm._v("\n            Offene Buchungen: "),
            _c("span", { staticClass: "m-2", staticStyle: { color: "red" } }, [
              _vm._v("Soll " + _vm._s(_vm._f("toCurrency")(_vm.minusSum))),
            ]),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "m-2", staticStyle: { color: "green" } },
              [_vm._v("Haben " + _vm._s(_vm._f("toCurrency")(_vm.plusSum)))]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "m-2" }, [
            _c(
              "span",
              {
                class: {
                  alert: _vm.invalidDiffernceInBooking,
                  "alert-danger": _vm.invalidDiffernceInBooking,
                },
                attrs: { role: "alert" },
              },
              [_vm._v("Differenz: " + _vm._s(_vm._f("toCurrency")(_vm.diff)))]
            ),
          ]),
          _vm._v(" "),
          _c(
            "b-button",
            {
              staticClass: "m-2",
              attrs: {
                type: "button",
                variant: "primary",
                disabled: _vm.invalidDiffernceInBooking,
              },
              on: {
                click: function ($event) {
                  return _vm.doSaveBooking()
                },
              },
            },
            [_vm._v("\n            Neue Buchung speichern\n        ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }