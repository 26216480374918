import AlertService from '@/shared/alert/alert.service';
import { IBookingEntry } from '@/shared/model/booking-entry.model';
import { IBookingSet } from '@/shared/model/booking-set.model';
import { IModificationSet } from '@/shared/model/modification-set.model';
import Component from 'vue-class-component';
import { Inject, Prop, Vue } from 'vue-property-decorator';
import BookingService from '../services/booking.service';
import AnonymousUserLoginService from '../services/user-login.service';
import { useBookingBalanceStore } from '../stores/booking-balance.store';
import { useBookingBookStore } from '../stores/booking-book.store';
import { useBookingModeStore } from '../stores/booking-mode-store';
import { useBookingsStore } from '../stores/booking-store';
import { useUserStore } from '../stores/user.store';
@Component
export default class NewBookingCardComponent extends Vue {
    @Inject('alertService') private alertService: () => AlertService;
    @Inject('bookingService') private bookingService: () => BookingService;
    @Inject('userLoginService') private userLoginService: () => AnonymousUserLoginService;

    @Prop()
    bookingOverviewMode: boolean;
    @Prop()
    correctionMode: boolean;

    bookingBalanceStore = useBookingBalanceStore();
    bookingModeStore = useBookingModeStore();
    bookingStore = useBookingsStore();
    userStore = useUserStore();

    minusSum = 0;
    plusSum = 0;
    diff = 0;
    invalidDiffernceInBooking = true;
    isCardHighlightActive = false;

    created() {
        this.calculateOpenBookingSums();
        this.bookingBalanceStore.$subscribe((mutation, state) => {
            this.calculateOpenBookingSums();
        });
    }

    get hasBookings() {
        return this.bookingStore.bookings.length > 0;
    }

    get canCancel() {
        return (
            this.bookingModeStore.editModeSelectBookingSet ||
            this.bookingModeStore.editModeCorrection ||
            this.bookingModeStore.editingInProgress
        );
    }

    get isEditInProgress() {
        return this.bookingModeStore.editingInProgress;
    }

    calculateOpenBookingSums() {
        this.minusSum = 0;
        this.plusSum = 0;
        this.bookingBalanceStore.openNewBookings.forEach(accountBookings => {
            this.plusSum += accountBookings.plus1 != null ? accountBookings.plus1 : 0;
            this.plusSum += accountBookings.plus2 != null ? accountBookings.plus2 : 0;
            this.minusSum += accountBookings.minus1 != null ? accountBookings.minus1 : 0;
            this.minusSum += accountBookings.minus2 != null ? accountBookings.minus2 : 0;
        });

        this.bookingBalanceStore.modifiedBookings.forEach(booking => {
            this.plusSum += booking.plus != null ? booking.plus : 0;
            this.minusSum += booking.minus != null ? booking.minus : 0;
        });

        this.diff = Math.abs(this.plusSum - this.minusSum);
        this.invalidDiffernceInBooking = Math.round(this.diff * 100) / 100 !== 0;
    }

    doStartNewBooking(): void {
        this.bookingModeStore.startEditingNewMode();
    }

    doStartCorrectionBooking(): void {
        this.userLoginService()
            .initUser()
            .then(user => {
                this.bookingModeStore.selectBookingSet();
                (<any>this).$router.push('/pages/bookingoverview');
            });
    }

    doSaveBooking(): void {
        this.userLoginService()
            .initUser()
            .then(user => {
                if (this.bookingModeStore.editModeNew) {
                    const newBookingEntries = this.bookingBalanceStore.openNewBookings
                        .map(openBooking => this.generateBookingEntriesFromInputFields(undefined, openBooking))
                        .reduce((previous, current) => [...previous, ...current], []);

                    if (newBookingEntries.length > 0) {
                        this.bookingService()
                            .createBooking({
                                bookingBookId: useBookingBookStore().activeBook.id,
                                creatorUserId: this.userStore.userId,
                                entries: newBookingEntries.map(entry => {
                                    return entry;
                                }),
                            })
                            .then(bookingSet => {
                                this.bookingBalanceStore.clear();
                                this.bookingModeStore.stopEditingMode();
                            })
                            .catch(error => {
                                if (error.response?.data?.detail) {
                                    this.alertService().showError(this, error.response?.data?.detail);
                                } else {
                                    this.alertService().showHttpError(this, error.response);
                                }
                            });
                    } else {
                        this.bookingBalanceStore.clear();
                        this.bookingModeStore.stopEditingMode();
                    }
                } else if (this.bookingModeStore.editModeCorrection && this.bookingBalanceStore.modifiedBookings.length > 0) {
                    const bookingSetId = this.bookingBalanceStore.modifiedBookings[0].bookingId;

                    const newBookingEntries = this.bookingBalanceStore.openNewBookings
                        .map(openBooking => this.generateBookingEntriesFromInputFields(bookingSetId, openBooking))
                        .reduce((previous, current) => [...previous, ...current], []);

                    const bookingSet = this.bookingStore.bookings.find(booking => booking.id === bookingSetId);

                    const modfiedBookingEntries = this.bookingBalanceStore.modifiedBookings.map(simpleModification => {
                        const orignalEntryCopy: IBookingEntry = { ...bookingSet.entries.find(entry => entry.id === simpleModification.id) };
                        orignalEntryCopy.plusValue = simpleModification.plus;
                        orignalEntryCopy.minusValue = simpleModification.minus;
                        orignalEntryCopy.bookingSet = null;
                        return orignalEntryCopy;
                    });

                    newBookingEntries.forEach(newEntry => {
                        modfiedBookingEntries.push(newEntry);
                    });

                    const modSet: IModificationSet = {
                        bookingBookId: useBookingBookStore().activeBook.id,
                        bookingSetId: bookingSetId,
                        creatorUserId: this.userStore.userId,
                        entries: modfiedBookingEntries,
                    };

                    this.bookingService()
                        .createModification(modSet)
                        .catch(error => {
                            if (error.response?.data?.detail) {
                                this.alertService().showError(this, error.response?.data?.detail);
                            } else {
                                this.alertService().showHttpError(this, error.response);
                            }
                        });

                    this.goBack();
                }
            });
    }

    generateBookingEntriesFromInputFields(bookingId: number, openBooking: OpenAccountBooking): IBookingEntry[] {
        const result: IBookingEntry[] = [];

        if (openBooking.minus1 != undefined && openBooking.minus1 > 0)
            result.push({
                minusValue: openBooking.minus1,
                plusValue: undefined,
                bookingAccountId: openBooking.accountId,
            });
        if (openBooking.minus2 != undefined && openBooking.minus2 > 0)
            result.push({
                minusValue: openBooking.minus2,
                plusValue: undefined,
                bookingAccountId: openBooking.accountId,
            });
        if (openBooking.plus1 != undefined && openBooking.plus1 > 0)
            result.push({
                minusValue: undefined,
                plusValue: openBooking.plus1,
                bookingAccountId: openBooking.accountId,
            });
        if (openBooking.plus2 != undefined && openBooking.plus2 > 0)
            result.push({
                minusValue: undefined,
                plusValue: openBooking.plus2,
                bookingAccountId: openBooking.accountId,
            });

        return result;
    }

    enableHighlightCard() {
        this.isCardHighlightActive = true;
    }

    disableHighlightCard() {
        this.isCardHighlightActive = false;
    }

    generateId(): number {
        return Math.floor(Math.random() * 10000000000) + 1;
    }

    goBack() {
        this.bookingBalanceStore.clear();
        this.bookingModeStore.stopEditingMode();
        (<any>this).$router.push({ path: '/pages/konto' });
    }
}
