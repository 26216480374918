/* eslint-disable prettier/prettier */
import { BookAccount, IBookAccount } from '@/shared/model/book-account.model';
import { defineStore } from 'pinia';

declare interface AccountStoreModel {
    accounts: IBookAccount[];
}

export const useAccountStore = defineStore('AccountStore', {
    state: (): AccountStoreModel => {
        return {
            accounts: [],
        };
    },

    actions: {
        addEmptyAccount() {
            this.accounts.push({
                title: '',
            });
        },
        init() {
            this.accounts = this.accounts.filter(account => account.title.trim() !== '');

            if (this.accounts.length === 0) {
                this.addEmptyAccount();
                this.addEmptyAccount();
            }
        },
        setAccounts(accounts: IBookAccount[]) {
            this.accounts = accounts;

            if (accounts.length <= 1) {
                this.addEmptyAccount();
            }
        },
        addAccount(account: IBookAccount) {
            this.accounts.push(account);
        },
        clear() {
            this.accounts = [];
        },
    },

    persist: true,
});
