import axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios';

import { useOrderingFavoritesStore } from '../stores/ordering-favorites.store';
import { IRestaurantEntry } from '@/shared/model/restaurant-entry.model';
import { useBookingBookStore } from '@/konto/stores/booking-book.store';
import { useUserStore } from '@/konto/stores/user.store';

const baseApiUrl = 'api/booking';
export default class OrderingService {
    private axios: AxiosInstance;

    bookingBookStore = useBookingBookStore();
    orderingFavoritesStore = useOrderingFavoritesStore();

    constructor() {
        this.axios = axios;
    }

    public loadFavoritesForBook(bookId: number) {
        const params = { privateAccessToken: useUserStore().privateAccessToken };
        const config: AxiosRequestConfig = { params };
        return axios.get(`${baseApiUrl}/booking-books/${bookId}/restaurant-entries`, config).then(response => {
            const restaurantEntries = response.data as IRestaurantEntry[];
            return restaurantEntries;
        });
    }

    public createFavorite(entry: IRestaurantEntry): Promise<IRestaurantEntry> {
        if (this.bookingBookStore.activeBook !== undefined) {
            const params = { privateAccessToken: useUserStore().privateAccessToken };
            const config: AxiosRequestConfig = { params };
            return axios
                .post(`${baseApiUrl}/booking-books/${this.bookingBookStore.activeBook.id}/restaurant-entries`, entry, config)
                .then(response => {
                    const entry = response.data as IRestaurantEntry;
                    this.orderingFavoritesStore.addNewFavorite(entry);
                    return entry;
                });
        } else {
            return Promise.reject('Unexpected Error');
        }
    }

    public updateFavorite(entry: IRestaurantEntry) {
        if (this.bookingBookStore.activeBook !== undefined) {
            const params = { privateAccessToken: useUserStore().privateAccessToken };
            const config: AxiosRequestConfig = { params };
            return axios
                .put(`${baseApiUrl}/booking-books/${this.bookingBookStore.activeBook.id}/restaurant-entries/${entry.id}`, entry, config)
                .then(response => {
                    const entry = response.data as IRestaurantEntry;
                    this.orderingFavoritesStore.putFavorite(entry);
                    return entry;
                });
        } else {
            return Promise.reject('Unexpected Error');
        }
    }

    public deleteFavorite(id: number): Promise<void> {
        if (this.bookingBookStore.activeBook !== undefined) {
            const params = { privateAccessToken: useUserStore().privateAccessToken };
            const config: AxiosRequestConfig = { params };
            return axios
                .delete(`${baseApiUrl}/booking-books/${this.bookingBookStore.activeBook.id}/restaurant-entries/${id}`, config)
                .then(response => {
                    this.orderingFavoritesStore.deleteFavorite(id);
                });
        } else {
            return Promise.reject('Unexpected Error');
        }
    }

    public updateBookingAccountTitle(entry: IRestaurantEntry): Promise<IRestaurantEntry> {
        const params = { privateAccessToken: useUserStore().privateAccessToken };
        const config: AxiosRequestConfig = { params };
        return axios
            .patch(`${baseApiUrl}/booking-books/${this.bookingBookStore.activeBook.id}/restaurant-entries/${entry.id}`, entry, config)
            .then(response => {
                return response.data;
            });
    }
}
