var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    {
      attrs: {
        "border-variant": "primary",
        header: "Teilnehmer mit Kontoübersicht",
        "header-bg-variant": "primary",
        "header-text-variant": "white",
        align: "left",
      },
    },
    [
      _c("div", { staticClass: "m-2" }, [
        _vm._v("Kontosaldo mit Datum der letzten Buchung:"),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "accountOverview m-2" },
        _vm._l(_vm.accountStore.accounts, function (account) {
          return _c("account-overview-entry", {
            key: account.id,
            attrs: { account: account },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "b-button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.bookingOverviewMode,
              expression: "!bookingOverviewMode",
            },
          ],
          staticClass: "m-2",
          attrs: {
            type: "button",
            variant: "primary",
            disabled: _vm.adblockerStatusStore.adblockerActive,
          },
          on: {
            click: function ($event) {
              return _vm.doAddAccount()
            },
          },
        },
        [_vm._v("Neuer Teilnehmer")]
      ),
      _vm._v(" "),
      _vm.adblockerStatusStore.adblockerActive
        ? _c(
            "div",
            { staticClass: "alert alert-danger m-2", attrs: { role: "alert" } },
            [
              _c("strong", [_vm._v("Adblocker:")]),
              _vm._v(
                " Bitte deaktivieren Sie Ihren Adblocker um weitere Accounts hinzufügen zu können!\n    "
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }