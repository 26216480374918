import axios from 'axios';
import Component from 'vue-class-component';
import { Vue, Inject } from 'vue-property-decorator';
import AccountService from '@/account/account.service';
import AlertService from '@/shared/alert/alert.service';
import BookingService from '../services/booking.service';
import { IBookingBook } from '@/shared/model/booking-book.model';
@Component({
    watch: {
        $route() {
            this.$root.$emit('bv::hide::modal', 'new-booking-book');
        },
    },
})
export default class NewBookingBookForm extends Vue {
    @Inject('alertService') private alertService: () => AlertService;
    @Inject('bookingService') private bookingService: () => BookingService;

    public title = '';

    public get isValidForm(): boolean {
        return this.title.trim().length > 0;
    }

    public doSubmit(): void {
        this.bookingService()
            .createBookingBook(this.title)
            .then(result => {
                this.$root.$emit('bv::hide::modal', 'new-booking-book-modal');
            })
            .catch(error => {
                this.alertService().showHttpError(this, error);
            });
    }
}
