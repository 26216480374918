var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.saldo != 0 || _vm.account.title != ""
    ? _c("div", { staticClass: "accountOverviewEntry row" }, [
        _c("div", { staticClass: "col-4" }, [
          _vm._v(_vm._s(_vm.account.title) + " :"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-4" }, [
          _vm.saldo < 0
            ? _c("span", { staticStyle: { color: "red" } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("konto.minus-saldo")) +
                    " " +
                    _vm._s(_vm._f("toCurrency")(_vm.saldo))
                ),
              ])
            : _vm._e(),
          _vm.saldo >= 0
            ? _c("span", { staticStyle: { color: "green" } }, [
                _vm._v(
                  _vm._s(_vm.$t("konto.plus-saldo")) +
                    " " +
                    _vm._s(_vm._f("toCurrency")(_vm.saldo))
                ),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _vm.latestBooking
          ? _c("div", { staticClass: "col-4" }, [
              _c("span", [
                _vm._v(
                  _vm._s(_vm._f("formatDate")(_vm.latestBooking)) +
                    " (" +
                    _vm._s(_vm._f("formatTime")(_vm.latestBooking)) +
                    "Uhr)"
                ),
              ]),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }