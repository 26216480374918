import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore

// prettier-ignore
const Team = () => import('@/pages/team/team.vue');
// prettier-ignore
const Konto = () => import('@/pages/konto/konto.vue');
// prettier-ignore
const TeamDemo = () => import('@/pages/teamDemo/teamDemo.vue');
// prettier-ignore
const imprint = () => import('@/pages/imprint/imprint.vue');
// prettier-ignore
const privacy = () => import('@/pages/privacy/privacy.vue');
// prettier-ignore
const contact = () => import('@/pages/contact/contact.vue');
// prettier-ignore
const cookies = () => import('@/pages/cookies/cookies.vue');
// prettier-ignore
const bookingoverview = () => import('@/pages/bookingoverview/bookingoverview.vue');
// prettier-ignore
const correction = () => import('@/pages/correction/correction.vue');
// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default [
    {
        path: '/pages/team',
        name: 'Team',
        component: Team,
        children: [
            {
                path: '/pages/team/:publicAccessKey',
                name: 'Team',
                component: Team,
                children: [
                    {
                        path: '/pages/team/:publicAccessKey/:title',
                        name: 'Team',
                        component: Team,
                    },
                ],
            },
        ],
    },
    {
        path: '/pages/konto',
        name: 'Konto',
        component: Konto,
    },
    {
        path: '/pages/teamDemo',
        name: 'TeamDemo',
        component: TeamDemo,
    },
    {
        path: '/pages/imprint',
        name: 'imprint',
        component: imprint,
    },
    {
        path: '/pages/privacy',
        name: 'privacy',
        component: privacy,
    },
    {
        path: '/pages/contact',
        name: 'contact',
        component: contact,
    },
    {
        path: '/pages/cookies',
        name: 'cookies',
        component: cookies,
    },
    {
        path: '/pages/bookingoverview',
        name: 'bookingoverview',
        component: bookingoverview,
    },
    {
        path: '/pages/correction',
        name: 'correction',
        component: correction,
        children: [
            {
                path: '/pages/correction/:bookingSetId',
                name: 'correction',
                component: correction,
            },
        ],
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
];
