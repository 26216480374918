import VueAdBlockDetect from 'vue-adblock-detect';
import { useAdblockDetectorStore } from '@/core/adblockdetector/abocker-detector.store';

export default {
    mixins: [VueAdBlockDetect],
    beforeMount() {
        this.detectAdBlock().then(response => {
            const adblockerStatusStore = useAdblockDetectorStore();

            if (response) {
                adblockerStatusStore.setAdblockerStatus(true);
            } else {
                adblockerStatusStore.setAdblockerStatus(false);
            }
        });
    },
};
