import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

// prettier-ignore
const BookingEntry = () => import('@/entities/booking-entry/booking-entry.vue');
// prettier-ignore
const BookingEntryUpdate = () => import('@/entities/booking-entry/booking-entry-update.vue');
// prettier-ignore
const BookingEntryDetails = () => import('@/entities/booking-entry/booking-entry-details.vue');
// prettier-ignore
const BookAccount = () => import('@/entities/book-account/book-account.vue');
// prettier-ignore
const BookAccountUpdate = () => import('@/entities/book-account/book-account-update.vue');
// prettier-ignore
const BookAccountDetails = () => import('@/entities/book-account/book-account-details.vue');
// prettier-ignore
const BookingSet = () => import('@/entities/booking-set/booking-set.vue');
// prettier-ignore
const BookingSetUpdate = () => import('@/entities/booking-set/booking-set-update.vue');
// prettier-ignore
const BookingSetDetails = () => import('@/entities/booking-set/booking-set-details.vue');
// prettier-ignore
const ModificationSet = () => import('@/entities/modification-set/modification-set.vue');
// prettier-ignore
const ModificationSetUpdate = () => import('@/entities/modification-set/modification-set-update.vue');
// prettier-ignore
const ModificationSetDetails = () => import('@/entities/modification-set/modification-set-details.vue');
// prettier-ignore
const BookingBook = () => import('@/entities/booking-book/booking-book.vue');
// prettier-ignore
const BookingBookUpdate = () => import('@/entities/booking-book/booking-book-update.vue');
// prettier-ignore
const BookingBookDetails = () => import('@/entities/booking-book/booking-book-details.vue');
// prettier-ignore
const BookingUser = () => import('@/entities/booking-user/booking-user.vue');
// prettier-ignore
const BookingUserUpdate = () => import('@/entities/booking-user/booking-user-update.vue');
// prettier-ignore
const BookingUserDetails = () => import('@/entities/booking-user/booking-user-details.vue');
// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default {
    path: '/',
    component: Entities,
    children: [
        {
            path: 'booking-entry',
            name: 'BookingEntry',
            component: BookingEntry,
            meta: { authorities: [Authority.USER] },
        },
        {
            path: 'booking-entry/new',
            name: 'BookingEntryCreate',
            component: BookingEntryUpdate,
            meta: { authorities: [Authority.USER] },
        },
        {
            path: 'booking-entry/:bookingEntryId/edit',
            name: 'BookingEntryEdit',
            component: BookingEntryUpdate,
            meta: { authorities: [Authority.USER] },
        },
        {
            path: 'booking-entry/:bookingEntryId/view',
            name: 'BookingEntryView',
            component: BookingEntryDetails,
            meta: { authorities: [Authority.USER] },
        },
        {
            path: 'book-account',
            name: 'BookAccount',
            component: BookAccount,
            meta: { authorities: [Authority.USER] },
        },
        {
            path: 'book-account/new',
            name: 'BookAccountCreate',
            component: BookAccountUpdate,
            meta: { authorities: [Authority.USER] },
        },
        {
            path: 'book-account/:bookAccountId/edit',
            name: 'BookAccountEdit',
            component: BookAccountUpdate,
            meta: { authorities: [Authority.USER] },
        },
        {
            path: 'book-account/:bookAccountId/view',
            name: 'BookAccountView',
            component: BookAccountDetails,
            meta: { authorities: [Authority.USER] },
        },
        {
            path: 'booking-set',
            name: 'BookingSet',
            component: BookingSet,
            meta: { authorities: [Authority.USER] },
        },
        {
            path: 'booking-set/new',
            name: 'BookingSetCreate',
            component: BookingSetUpdate,
            meta: { authorities: [Authority.USER] },
        },
        {
            path: 'booking-set/:bookingSetId/edit',
            name: 'BookingSetEdit',
            component: BookingSetUpdate,
            meta: { authorities: [Authority.USER] },
        },
        {
            path: 'booking-set/:bookingSetId/view',
            name: 'BookingSetView',
            component: BookingSetDetails,
            meta: { authorities: [Authority.USER] },
        },
        {
            path: 'modification-set',
            name: 'ModificationSet',
            component: ModificationSet,
            meta: { authorities: [Authority.USER] },
        },
        {
            path: 'modification-set/new',
            name: 'ModificationSetCreate',
            component: ModificationSetUpdate,
            meta: { authorities: [Authority.USER] },
        },
        {
            path: 'modification-set/:modificationSetId/edit',
            name: 'ModificationSetEdit',
            component: ModificationSetUpdate,
            meta: { authorities: [Authority.USER] },
        },
        {
            path: 'modification-set/:modificationSetId/view',
            name: 'ModificationSetView',
            component: ModificationSetDetails,
            meta: { authorities: [Authority.USER] },
        },
        {
            path: 'booking-book',
            name: 'BookingBook',
            component: BookingBook,
            meta: { authorities: [Authority.USER] },
        },
        {
            path: 'booking-book/new',
            name: 'BookingBookCreate',
            component: BookingBookUpdate,
            meta: { authorities: [Authority.USER] },
        },
        {
            path: 'booking-book/:bookingBookId/edit',
            name: 'BookingBookEdit',
            component: BookingBookUpdate,
            meta: { authorities: [Authority.USER] },
        },
        {
            path: 'booking-book/:bookingBookId/view',
            name: 'BookingBookView',
            component: BookingBookDetails,
            meta: { authorities: [Authority.USER] },
        },
        {
            path: 'booking-user',
            name: 'BookingUser',
            component: BookingUser,
            meta: { authorities: [Authority.USER] },
        },
        {
            path: 'booking-user/new',
            name: 'BookingUserCreate',
            component: BookingUserUpdate,
            meta: { authorities: [Authority.USER] },
        },
        {
            path: 'booking-user/:bookingUserId/edit',
            name: 'BookingUserEdit',
            component: BookingUserUpdate,
            meta: { authorities: [Authority.USER] },
        },
        {
            path: 'booking-user/:bookingUserId/view',
            name: 'BookingUserView',
            component: BookingUserDetails,
            meta: { authorities: [Authority.USER] },
        },
        // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
    ],
};
