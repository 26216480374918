import { Component, Inject, Vue } from 'vue-property-decorator';
import LoginService from '@/account/login.service';
import AccountService from '@/account/account.service';
import TranslationService from '@/locale/translation.service';

import EntitiesMenu from '@/entities/entities-menu.vue';
import { IBookingBook } from '@/shared/model/booking-book.model';
import { useBookingBookStore } from '@/konto/stores/booking-book.store';
import BookingService from '@/konto/services/booking.service';

@Component({
    components: {
        'entities-menu': EntitiesMenu,
    },
})
export default class JhiNavbar extends Vue {
    @Inject('loginService')
    private loginService: () => LoginService;
    @Inject('translationService') private translationService: () => TranslationService;
    @Inject('bookingService') private bookingService: () => BookingService;
    @Inject('accountService') private accountService: () => AccountService;
    public version = 'v' + VERSION;
    private currentLanguage = this.$store.getters.currentLanguage;
    private languages: any = this.$store.getters.languages;
    private hasAnyAuthorityValues = {};

    teams: IBookingBook[] = [];
    public get hasTeams() {
        return this.teams.length > 0;
    }

    created() {
        this.translationService().refreshTranslation(this.currentLanguage);

        useBookingBookStore().$subscribe((mutation, state) => {
            this.teams = useBookingBookStore().books.filter(book => book.teamBook == true);
        });

        this.teams = useBookingBookStore().books.filter(book => book.teamBook == true);
    }

    public subIsActive(input) {
        const paths = Array.isArray(input) ? input : [input];
        return paths.some(path => {
            return this.$route.path.indexOf(path) === 0; // current path starts with this path string
        });
    }

    public changeLanguage(newLanguage: string): void {
        this.translationService().refreshTranslation(newLanguage);
    }

    public isActiveLanguage(key: string): boolean {
        return key === this.$store.getters.currentLanguage;
    }

    public logout(): Promise<any> {
        localStorage.removeItem('jhi-authenticationToken');
        sessionStorage.removeItem('jhi-authenticationToken');
        this.$store.commit('prelogout');
        this.$store.commit('logout');
        if (this.$route.path !== '/') {
            return this.$router.push('/');
        }
        return Promise.resolve(this.$router.currentRoute);
    }

    public openLogin(): void {
        this.loginService().openLogin((<any>this).$root);
    }

    public get authenticated(): boolean {
        return this.$store.getters.authenticated;
    }

    public hasAnyAuthority(authorities: any): boolean {
        this.accountService()
            .hasAnyAuthorityAndCheckAuth(authorities)
            .then(value => {
                this.hasAnyAuthorityValues[authorities] = value;
            });
        return this.hasAnyAuthorityValues[authorities] ?? false;
    }

    public get openAPIEnabled(): boolean {
        return this.$store.getters.activeProfiles.indexOf('api-docs') > -1;
    }

    public get inProduction(): boolean {
        return this.$store.getters.activeProfiles.indexOf('prod') > -1;
    }
}
